import BaseAPI from '../BaseAPI.js'

/**
 * 文档服务API
 * 
 * @author Daniel
 * @date 
 */
export default class DocumentAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/document"
        this.toolsModuleName = "/dict"
        this.toolsdelModuleName = "/tc"
        // this.toolsModuleName = "/admin"  //1205
        // this.toolsdelModuleName = "/admin"  //1205
    }

    /**
     * 获取文件夹的子级文档结构
     * @param { 
     *  id 文档id;
     *  name: 文档名称
     *  pageNum 第几页;
     *  pageSize 每页数量 }  queryInfo
     */
    queryChildren(queryInfo) {
        return this.ajax.post(`${this.moduleName}/ls`, queryInfo)
    }

    /**
     * 获取文件夹的子级分享文档列表
     * @param {*} queryInfo 
     */
    queryShareChildren(queryInfo) {
        return this.ajax.get(`${this.moduleName}/${queryInfo.id}/share/ls`)
    }

    /**
     * 获取文件夹的组织子级文档结构
     * @param { 
     *  id 文档id;
     *  name: 文档名称
     *  pageNum 第几页;
     *  pageSize 每页数量 }  queryInfo
     */
    queryOrgChildren(queryInfo) {
        return this.ajax.post(`${this.moduleName}/org/ls`, queryInfo)
    }

    /**
     * 新建文件夹
     * @param {*} parentFolderId 
     * @param {
     *  0: 个人
     *  1：组织
     *  2：群组
     * } docType
     * @param {*} name 
     * @param {
     *    share: 是否共享
     * } attrs 文件夹属性
     */
    createFolder(parentFolderId, docType, name, share, rootId) {
        // return this.ajax.post(`${this.moduleName}/${parentFolderId}?name=${name}&share=${attrs.share}`)
        if (rootId) {
            return this.ajax.post(`${this.moduleName}/mkdir`, {
                parentId: parentFolderId,
                docType,
                name,
                share,
                rootId
            })
        }
        return this.ajax.post(`${this.moduleName}/mkdir`, {
            parentId: parentFolderId,
            docType,
            name
        })
    }

    /**
     * 重命名文档
     * @param {*} documentId 
     * @param {*} newName 
     */
    rename(documentId, newName) {
        return this.ajax.post(`${this.moduleName}/${documentId}/rename?newName=${newName}`)
    }

    /**
     * 设置属性
     * @param {*} documentId 
     * @returns 
     */
    getDocumentProps(documentId) {
        return this.ajax.get(`${this.moduleName}/${documentId}`)
    }

    /**
     * 移除文档
     * @param {*} documentId 
     */
    remove(documentId) {
        return this.ajax.delete(`${this.moduleName}/${documentId}`)
    }

    /**
     * 阅读文档
     * @param {*} documentId 
     */
    read(documentId) {
        return this.ajax.get(`${this.moduleName}/${documentId}/reader`)
    }

    /**
     * 下载文件
     * @param {*} documentId 文档ID
     * @param {*} protectType 保护类型
     * @param {*} encryptPwd 保护密码
     * @param {*} token 会话Token
     */
    download(documentId, protectType, encryptPwd, token) {
        const url = `${this.moduleName}/download?documentId=${documentId}&types=${protectType}&encryptPwd=${encryptPwd}&access_token=${token}`
        window.location.href = `${window.origin}${this.baseURL}` + url
    }

    /**
     * 获取脱链文件的阅读码
     * @param {*} documentFileId 文档下载ID
     * @param {*} time 阅读时间戳
     */
    getOfflineFileCode(documentFileId, time) {
        return this.ajax.get(`${this.moduleName}/download/pwd?id=${documentFileId}&t=${time}`)
    }

    /**
     * 加载文件缩略图
     * @param {*} documentId 文档ID
     * @param {*} pageSize 分页大小，默认20
     * @param {*} pageNum 当前页码
     */
    thumbnail(documentId, pageSize = 20, pageNum = 1) {
        return this.ajax.post(`${this.moduleName}/thumbnail/list`, {
            id: documentId,
            pageSize,
            pageNum
        })
    }

    /**
     * 获取文档属性
     * @param {*} id 文档ID
     */
    getDocumentOption(id) {
        return this.ajax.get(`${this.moduleName}/getDocumentCustomizeTag/${id}`)
    }

    /**
     *设置文档属性
     * @param {*} description 文档描述
     * @param {*} id 文档ID
     * @param {*} documentName 文档名
     * @param {*} tag 文档检索标签
     */
    updateDocumentOption({ description, documentId, documentName, tag, miji, shareFlag, isPublic }) {

        return this.ajax.post(`${this.moduleName}/updateDocumentCustomizeTag`, {
            documentId: documentId,
            description: description,
            documentName: documentName,
            tag: tag,
            miji: miji,
            shareFlag,
            isPublic
        })
    }

    /**
     * 阅读日志
     * @param {*} data 
     */
    readLog(data) {
        return this.ajax.post(`${this.moduleName}/reader/log`, data)
    }

    // 数据脱敏
    getEnvelopList(documentId) {
        return this.ajax.get(`${this.moduleName}/${documentId}/envelopsFields`)
    }

    /**
     * 复制文件
     * @param {*} data
     */
    copyDocument(data) {
        return this.ajax.post(`${this.moduleName}/file/copy`, data)
    }

    /**
     * 移动文件
     * @param {*} data
     */
    moveDocument(data) {
        return this.ajax.post(`${this.moduleName}/file/transfer`, data)
    }

    /**
     * 数据脱敏 设置的时候 检测是否遮掩
     * @param {*} documentId 
     * @returns 
     */
    checkDocList(documentId) {
        return this.ajax.get(`${this.moduleName}/checkDocumentCanConver/${documentId}`)
    }

    /**
     * 多选删除文档
     * @param {*} id
     */
    checkedRemove(id) {
        return this.ajax.post(`${this.moduleName}/removeDocuments`, id)
    }

    /**
     * 批量检测文档的分享权限，分档是否可以阅读
     */
     batchCheckDocumentSharePermission(query){
        return this.ajax.post(`${this.moduleName}/batchCheckDocumentSharePermission`,query)
     }

    /**
     * 批量转换ofd文件
     */
     batchConvertOFD(query){
        return this.ajax.post(`${this.moduleName}/batchConvertOFD`,query)
    }

    /**
     * 批量检测文件是否是分享文件
     */
    checkDocumentHasShare(query){
        return this.ajax.post(`${this.moduleName}/checkDocumentHasShare`,query)
    }
    

   /**
    * 转换文档为OFD文件
   */
   transformToOFD(documentId) {
    return this.ajax.put(`${this.moduleName}/transformToOFD/${documentId}`)
  }

  /**
   * 获取新建文件地址
   * {docType: 0个人1组织2部门,parentId:父级文件id,orgId:部门id,doc:文件名,fileType:文件类型 w文字文件 s表格文件 p演示文件}
   *
   */
  wpsGetNewUrl(query){
    return this.ajax.get(`/document/coordination/getNewUrl`,query)
  }

  wpsOpenFile(query){
    return this.ajax.get(`/document/coordination/openFile`,query)
  }

    //通过onlyoffice方式打开文件，获取对应参数
    getOnlyOfficeParameter(id){
        return this.ajax.get(`/document/onlyOffice/config/${id}`)
    }

    // 归档目标文件
    shareArchiveDocument(query){
        return this.ajax.post(`/share/archive/document`,query)
    }

    // 预览文件
    documentPreview(id,token){
        if(token){
            return this.ajax.post(`/document/preview/file?id=${id}`,{},{
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + token
                }
            }) 
        }else{
            return this.ajax.post(`/document/preview/file?id=${id}`,{},{
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }) 
        }
       
    }

    //  多个文件批量添加手写签批
    documentSignAdd(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/sign/add`,query)
    }

    // 多个文件批量添加电子签
    documentSignSignature(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/sign/signature`,query)
    }

    // 验签
    verificationSignature(query){
        return this.ajax.get(`${this.toolsdelModuleName}/sign/testVerifyTestMGomez`,query)
    }

    // 验证链接是否过期
    getSignTestExpire(uuid){
        let formData = new FormData();
        formData.append('id',uuid)
        return this.ajax.post(`${this.toolsdelModuleName}/sign/testExpire`,formData)
    }
    // 验证链接是否正确
    getSignTestCode(uuid,code){
        let formData = new FormData();
        formData.append('id',uuid)
        formData.append('code',code)
        return this.ajax.post(`${this.toolsdelModuleName}/sign/testCode`,formData)
    }

    /**
     * 文档工具文件脱敏
     */
    addSecurityCover(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/security/cover`,query)
        // return this.ajax.post(`/security/cover`,query)//网安
    }

    /**
     * 文档工具OCR识别图片
     */
    ocrIdentifyImage(query){
        return this.ajax.post(`${this.toolsdelModuleName}/content/OCR/image`,query)
    }

    /**
     * 文档工具OCR导出文件
     */
     exportOcrIdentifyFile(query){
        return this.ajax.post(`${this.toolsdelModuleName}/content/OCR/image/export`,query)
    }

    /**
     * 文档工具PDF删除页面
     */
    pdfDeletePage(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/handle/page/delete`,query)
    }

    /**
     * 文档工具图片遮掩
     */
    imageCover(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/security/imageCover`,query)
    }

    /**
     * 文档工具PDF排序
     */
    pdfHandleSort(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/handle/sort`,query)
    }

    /**
     * 文档工具图片转pdf
     */
     imageConverPdf(query){
        return this.ajax.post(`${this.toolsdelModuleName}/async/convert/image2pdf`,query)
    }

    /**
     * 处理工具后文档到我的网盘
     */
     saveToolhandleFileToMydocument(query,token){
        return this.ajax.post(`/document/saveToolhandleFileToMydocument`,query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 获取文件在我的文档到工具中使用
     */
    getNetDiskFile(query,token){
        return this.ajax.post(`/document/getNetDiskFile`,query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    // 文档库置顶
    documentFileSort(query){
        return this.ajax.put(`/document/update/index/sort`,query)
    }
    // 取消文档库置顶
    cancelDocumentFileSort(documentId){
        return this.ajax.put(`/document/cancel/index/sort/${documentId}`)
    }
   
    // 我的文档置顶
    myDocumentFileSort(query){
        return this.ajax.put(`/document/personal/sort`,query)
    }

    // 取消 我的文档置顶
    cancelMyDocumentFileSort(documentId){
        return this.ajax.put(`/document/personal/sort/cancel/${documentId}`)
    }

    // 管理员获取某个成员的存储空间
    getUserDist(userId){
        return this.ajax.get(`/document/admin/check/user/dist/${userId}`)
    }

    // 管理员更改某个成员的存储空间
    addUserDist(query){
        return this.ajax.put(`/usercenter/user/update/storage/space`,query)
    }

    
    
    
    


}
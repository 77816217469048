import BaseAPI from '../BaseAPI.js'

/**
 * 用户服务API
 * 
 * @author Daniel
 * @date 
 */
export default class LoginAPI extends BaseAPI {
    constructor() {
        super()
        this.baseAuthorization = "Basic bGlhbmtvbmc6MTIzNDU2"

        this.commConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": this.baseAuthorization
            }
        }
    }

    /**
     * 使用账号密码登陆
     * @param {string} loginName 
     * @param {string} loginPassword 
     * @param {string} vcode 
     */
    loginByPassword(loginName, loginPassword ,uuid,validateCode) {
        return this.ajax.post("/auth/oauth/token", {
            scope: "server",
            grant_type: "captcha",
            username: loginName,
            password: loginPassword,
            uuid: uuid,
            validateCode: validateCode
        }, this.commConfig)
    }

      /**
     * 工具平台 使用账号密码登陆，带图形验证码
     * @param {string} loginName 
     * @param {string} loginPassword 
     * @param {string} uuid 
     * @param {string} validateCode 
     */
    loginByToolsPassword(loginName, loginPassword ,uuid ,validateCode ) {
        return this.ajax.post("/auth/oauth/token", {
            scope: "server",
            grant_type: "captcha",
            username: loginName,
            password: loginPassword,
            uuid,
            validateCode
        }, this.commConfig)
    }

    /**
     * 图片获取验证码
     */
     getCaptcha() {
        return this.ajax.get("/auth/captcha")
    }


    /**
     * 使用手机号登陆
     * @param {string} userPhone 
     * @param {string} vcode 
     */
    loginByPhone(userPhone, vcode) {
        return this.ajax.post("/auth/mobile/token/sms", {
            type: "sms",
            identify: userPhone,
            code: vcode
        }, this.commConfig)
    }

    /**
     * 使用Client Code进行登陆
     * @param {*} code 
     */
    loginBySNSCode(code) {
        return this.ajax.post(`/auth/mobile/token/sns`, {
            identify: code,
            type: "mini"
        }, this.commConfig)
    }

    /**
     * 使用微信小程序一键登陆
     * @param {*} loginCode 
     * @param {*} ivData 
     * @param {*} encrypData 
     * @param {*} userEncryptedData 
     */
    loginByWXMP(loginCode, ivData, encrypData, userEncryptedData) {
        return this.ajax.post(`/auth/mobile/mini/fast`, {
            type: "fast",
            identify: loginCode,
            ivData: ivData,
            encrypData: encrypData,
            userEncryptedData: userEncryptedData
        }, this.commConfig)
    }

    /**
     * 发送登陆验证码
     * 
     * @param {string} userPhone
     */
    sendLoginCode(userPhone) {
        return this.ajax.get(`/usercenter/mobile/send?phone=${userPhone}`)
    }

    /**
     * 修改密码
     * @param {string} phone 
     * @param {string} newPassword 
     * @param {string} code 
     */
    resetPassword(phone, newPassword, vcode) {
        return this.ajax.put("/usercenter/user/forgotPassword", {
            phone,
            newPassword,
            code: vcode
        })
    }

    /**
     * 退出登陆
     */
    logout() {
        return this.ajax.get("/auth/logout/user")
    }

    /**
     * 当前登陆用户绑定社交平台
     * @param {*} type 社交平台类型
     * @param {*} bindCode 绑定码
     */
    bindSocial(type, bindCode) {
        return this.ajax.post(`/usercenter/user/bind/social`, {
            type,
            identify: bindCode
        })
    }

    /**
     * 刷新用户Token
     * 
     * @param {string} refreshToken
     */
    refreshToken(refreshToken) {
        return this.ajax.post("/auth/oauth/token", {
            grant_type: "refresh_token",
            refresh_token: refreshToken
        }, this.commConfig)
    }

    /**
     * 检查服务器code是否有效
     * 
     * @param {string} refreshToken
     */
    checkCode(cacheCode) {
        return this.ajax.get(`/usercenter/user/check/mini/sns/${cacheCode}`)
    }

    /**
     * 工具端 退出登陆
     */
    toolLogout(token) {
        return this.ajax.get("/auth/logout/user", {}, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + token
            }
        })
    }


    /**
     * 工具web端 获取登录方式
     */
    sysConfigLogin(){
        return this.ajax.get("/sysConfig/login", {})
    }

     /**
     * 工具web端 获取二维码
     */
    getWxQrCode(){
        return this.ajax.get("/usercenter/wxoa/createQr")
    }

     /**
     * 工具web端 轮询是否扫码
     */
    pollingScanCode(ticket){
        return this.ajax.get("usercenter/wxoa/scan/event/"+ticket)
    }


    /**
     * 工具web端 微信登录
     */
     toWxLogin(ticket){
        return this.ajax.post("/auth/mobile/token/qr?type=oa_qr&identify="+ticket,{}, this.commConfig)
    }

    // 钉钉登录
    toDingDingLogin(identify){
        return this.ajax.post("/auth/mobile/mini/ding",{
            type:'ding_mini_fast',
            identify
        }, this.commConfig)
    }

    /**
     * 获取用户信息
     * @returns 
     */
    getUserInfos({access_token}) {
        return this.ajax.get('/tool/strategy/user/info', {},{
            headers: {
                "Authorization": `Bearer ${access_token}`
            }
        })
    }


}
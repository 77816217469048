import BaseAPI from '../BaseAPI.js'

/**
 * 1205 爱树页面需要对接
 */
export default class CustomAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = '/admin'
    }

    /**
     * 获取用户信息
     * @param {*} query 
     * @returns 
     */
    getThirdAsUserInfo(query) {
        return this.ajax.post(`${this.moduleName}/third/as/user/info?token=${query.token}`)
    }

    /** 获取入口文档库接口 */
    getThirdAsLabFile(token) {
        return this.ajax.get(`${this.moduleName}/cloud/entry-doc-lib`, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /** 进入文件夹 */
    getEnterDoc(query, token) {
        return this.ajax.post(`${this.moduleName}/cloud/dir/list`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /** 上传 */
    thirdAsUpload(query, token) {
        return this.ajax.post(`${this.moduleName}/cloud/local`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /** 保存 */
    thirdAsFileSave(query, token) {
        return this.ajax.post(`${this.moduleName}/cloud/save`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }
    /** 获取爱树退出地址 */
    getAsURL() {
        return this.ajax.get(`${this.moduleName}/getValue?key=asUrl`)
    }
    /** 获取文件夹权限 */
    getFolderPermission(query,token) {
        return this.ajax.post(`${this.moduleName}/cloud/checkDirPer`,query, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
    }

}
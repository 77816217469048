/**
 * 异步js
 * 
 * @author Daniel
 */
export function require(url){
  return new Promise((resolve, reject) => {
    
    const jScripts = document.getElementsByTagName("script")

    let hasLoaded = false
    for(let i = 0, ilen = jScripts.length; i < ilen; i++){
      if(jScripts[i].src == url){
        hasLoaded = true
        break
      }
    }

    if(!hasLoaded){
      //加载
      const scriptLoader = document.createElement('script')
      scriptLoader.type = 'text/javascript'
      scriptLoader.src = url

      scriptLoader.onload = () => {
        resolve()
      }

      scriptLoader.onerror = () => {
        reject()
      }

      document.body.appendChild(scriptLoader)
    }else{
      resolve()
    }
  })
}
import BaseAPI from '../BaseAPI.js'

/**
 * 系统配置服务API
 * 
 * @author Daniel
 * @date 
 */
export default class MsgAPI extends BaseAPI{

  constructor(){
    super()

    this.moduleName = "/msg"
  }

  /**
   * 获取个人站内新消息
   */
  queryUserMessage(query){
    return this.ajax.post(`${this.moduleName}/user/queryAll`,query);
  }


  /**消息中根据主题id获取对应详情
   */
  getDetailByMessageId(requestId){
    return this.ajax.get(`/share/request/request/detail/${requestId}`,{});
  }
  /**
     * 获取未读消息
     */
  UnMessage(query){
    return this.ajax.post(`${this.moduleName}/user/queryUnread`,query);
  }

  

  /**
   * 跟新用户信息
   * 
   * @param {*} server 
   */
  updateUserMessageState(messageId){
    return this.ajax.put(`${this.moduleName}/user/${messageId}`);
  }
  /**
   * 删除用户个人消息
   * @param {*} messageId 
   * @returns 
   */
  removeUserMessage(messageId){
    return this.ajax.del(`${this.moduleName}/user/${messageId}`);
  }

    /**
   * 参数设置
   * @param {*} query 
   * @returns 
   */
    getParameterList(query){
      return this.ajax.post(`/sysConfig/list`,query);
    }
    addParameter(query){
      return this.ajax.post(`/sysConfig`,query);
    }
    updateParameter(query){
      return this.ajax.put(`/sysConfig`,query);
    }
    deleteParameterById(id){
      return this.ajax.delete(`/sysConfig/${id}`);
    }
    getParameterDetail(id){
      return this.ajax.get(`/sysConfig/${id}`);
    }
    // 根据key值获取参数信息
    getValueByKey(key){
      return this.ajax.get(`/sysConfig/configKey/${key}`);
    }
    // 获取所有参数信息
    getAllByKey(){
      return this.ajax.get(`/sysConfig/listAll`);
    }
    // 获取万维大屏配置项接口
    getWanWeiConfigKey(){
      return this.ajax.get(`/sysConfig/prop/biz.saas.largeScreen.download`);
    }

    // 获取nacos配置信息
    getNocosConfigKey(key){
      return this.ajax.get(`/sysConfig/prop/${key}`);
    }
    


     /**
   * app升级服务
   * @param {*} query 
   * @returns 
   */
    getAppList(query){
      return this.ajax.post(`/version/list`,query);
    }
    addApp(query){
      return this.ajax.post(`/version/add`,query);
    }
    updateApp(query){
      return this.ajax.post(`/version/edit`,query);
    }
    deleteAppById(id){
      return this.ajax.delete(`/version/${id}`);
    }
    // 设置当前版本为发布版本
    setCurrtentVersion(id){
      return this.ajax.put(`/version/updateCurrentVersion/${id}`);
    }
    // 检测是否需要更新
    checkUpdate(query){
      return this.ajax.post(`/version/checkUpdate`,query);
    }

    // 溯源记录
    getRetrospectList(query){
      return this.ajax.post(`/document/retrospect/list`,query);
    }

    // 上传溯源文件
    uploadRetrospectFile(file){
      
      let formData = new FormData();
      formData.append('file',file.raw)
      return this.ajax.post(`/document/retrospect/upload`,formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    }

}
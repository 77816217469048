<template>
  <div class="ofd-viewer"></div>
</template>

<script>
  import { require } from '../utils/require.js'
  import { getQueryParams } from "../utils/query.js"
  import { Store } from '@lk/cache'
  export default {
    props: {
      // zoom: {
      //   type: Number,
      //   default: 0
      // },
      // type: {
      //   type: String,
      //   default: "reader"
      // }
    }, 
    data(){
      return {
        scofdReader: null,
        ofdReader: {
          loaded: false,
          instance: null
        },
        scaleX:1
      }
    },
    computed:{
      //是否是APP环境
      isApp(){
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
      }
    },
    mounted () {
      this.install()
      
           
    },
    watch: {
      scale(){
        this.$emit('getScaleX',this.scaleX)
      }
    },
    methods: {
      //安装阅读器
      install(){
        if(!window.SCOFDReader){
          require("ofd-pc-reader/SCOFDReader.umd.min.js").then(()=> {
            console.log("阅读器加载完成", Store.get('lic'))
            this.ofdReader.instance = new window.SCOFDReader(this.$el,  Store.get('lic'), {
              showTools:getQueryParams("model")=='teamwork', //是否是协同文件
              showState: false,
              showContextMenu: false,
              showDocListBar: false,
              showLeftPanel: false,
              showSave:getQueryParams("model")=='teamwork',
              showSaveAs:getQueryParams("model")=='teamwork',
              // edit:getQueryParams('teamwork')? true:false,
              scale:  1,
              // ratio:2,
              // maxScale:4,
              // showOpenFile: false,
              // showSealsign: false,   //隐藏签章按钮
              // showVerifysign: false,   //隐藏验章按钮
              // showMenu: false,  // true-显示/false-隐藏, 菜单
              // showJuXing: true,  // true-显示/false-隐藏, 矩形
              // showQianBi: true,  // true-显示/false-隐藏, 铅笔
              // showXianDuan: true,  // true-显示/false-隐藏, 线段
              // showXiangPi: true,  // true-显示/false-隐藏, 橡皮
              // showBiaoZhu: true,  // true-显示/false-隐藏, 高亮标注
              // showShanChuXian: true,  // true-显示/false-隐藏, 删除线
              // showChaRuWenBen: true,  // true-显示/false-隐藏, 插入文本
              // showXiaHuaXian: true,  // true-显示/false-隐藏, 下划线
              // showMove: true, // true-显示 / false-隐藏  移动
              // showChooseText: true, // true-显示 / false-隐藏  选择文本
              // showAnnotation: true,  // true-显示/false-隐藏, 选择注释
              // showRotateLeft: true,  // true-显示/false-隐藏, 左旋转
              // showRotateRight: true,  // true-显示/false-隐藏, 右旋转

              // showPrintFile: print === "1", //隐藏打印
              // showExportFile: download === "1", //隐藏下载
              })

            this.ofdReader.instance.setFileServURL(window.origin+"/prod-api/fs/")
            console.log('阅读模式')

            this.ofdReader.instance.on("document:page:render", () => {
              
              this.scaleX = this.ofdReader.instance.getScale()
              if(getQueryParams("model")!= 'teamwork' && getQueryParams("pageIndex")){
                this.ofdReader.instance.gotoPage(Number(getQueryParams("pageIndex")))
              }
              
              this.$emit('getScaleX',this.scaleX);
              this.$emit('loadEnd')
            })

            this.ofdReader.instance.on("document:ready", ({ pageNum}) => {
              this.$emit('getPage',{ "pageNum" : pageNum })
            })
            
            this.ofdReader.instance.on("document:page:active", (pageNumber) => {
              if(document.getElementsByClassName('panel-top').length == 0){
                this.pageNumber = pageNumber
                this.$emit('getPage',{ "nowPage" : pageNumber })
              }else{
                 this.$emit('getPage',{ "nowPage" : this.pageNumber })
              }

            })

            // 保存成功，添加提示信息
            document.addEventListener('click',(e)=>{
              if(e.target.className == 'sc-tool-btn sc-tool-icons icon-save'){
                this.$message.success('保存成功！')
                 // 链控通讯
                  window.parent.postMessage({
                    cmd: 'saveDocument',
                  }, '*')
              }
            })
          

            this.$emit("onInited");
          })
        }
      },

      /**
       * 根据Ticket进行登陆
       */ 
      openByTicket(ticket){
        try{
          this.ofdReader.instance.openFile(ticket)
        }catch(e){
          console.log(e)
        }
      },

      // 缩放
      setScale(zoom){
        if(zoom == 0 ){
           this.$message.error("不能再缩小了")
        }else{
          this.ofdReader.instance.setScale(zoom)
        }
      },

      // 翻页
      gotoPage(type){
        if(type == 'next'){
          this.ofdReader.instance.nextPage()
        }else{
          this.ofdReader.instance.previousPage()
        }
      },

      showPrint(){
        console.log('打印')
        this.ofdReader.instance.printFile()
      },
      downLoad(){
        console.log('下载')
        // console.log(this.ofdReader.instance.downloadFile)
        // this.ofdReader.instance.downloadFile()
      },

      // 设置翻页模式 1是翻页 2是连续
      setpageView(type){
        this.ofdReader.instance.setPageScroll(type)
      },

      //高亮
      highlight(blocks){
        if(blocks && Array.isArray(blocks) && blocks.length > 0){

          const highligthParams = blocks.reduce((params, item) => {
            item.regions.forEach(reg => {
              //页面索引处理
              const pageIndex = reg.pageIndex
              params.firstPageIndex = params.firstPageIndex 
                                        ? Math.min(params.firstPageIndex, pageIndex)
                                        : pageIndex

              //高亮数据处理                              
              const pageId = reg.pageId
              const pageTextList = params[pageId] || (params[pageId] = [])

              const boundary = reg.boundary.split(' ')
              pageTextList.push({
                left: boundary[0],
                top: boundary[1],
                width: boundary[2],
                height: boundary[3]
              })
            })

            return params
          }, {
            firstPageIndex: null,
          })

          this.ofdReader.instance.callDoc("setHighlight", highligthParams)
          this.ofdReader.instance.gotoPage(highligthParams.firstPageIndex)
        }
      },

      //清除高亮
      cleanHighlight(){
        this.ofdReader.instance.clearHighlight()
      }
    }
  }
</script>

<style scoped>
.ofd-viewer{
  width: 500px;
  height: 500px;
  transform: scale(3);
}
</style>
import BaseAPI from '../BaseAPI.js'

/**
 * 统计服务API
 * 
 * @author Daniel
 * @date 
 */
export default class StatisticsAPI extends BaseAPI{

  constructor(){
    super()

  }

  /**
   * 当前用户分享数
   * @return {
   *  
   * }
   */
  userShareCount(){
    return this.ajax.get(`/share/request/myCount`)
  }

  /**
   * 当前用户文档数
   * @return {
   *  data: {
   *    userUploadCount: 上传的总文档数
   *  }
   * }
   */
  userDocumentCount(){
    return this.ajax.get(`/document/user/statistics`)
  }

  /**
   * 当前用户收到的分享数
   */
  userShareToMeCount(){

  }
  

  /*** 工具统计服务接口开始*/

  /**
   * 文档使用工具top
   * @param {*} endDay   结束时间
   * @param {*} startDay  开始时间
   */
  statisTop10(query){
    return this.ajax.post(`/tool/statis/top`,query)
  }

  /**
   * 文档使用工具类型统计
   * @param {*} endDay   结束时间
   * @param {*} startDay  开始时间
   */
   statisByType(query){
    return this.ajax.post(`/tool/statis/statisByType`,query)
  }

  /**
   * 实时处理情况
   */
   statisRealProgress(){
    return this.ajax.post(`/tool/statis/realProgress`)
  }

  /**
   * 文档使用工具排行
   * @param {*} endDay   结束时间
   * @param {*} startDay  开始时间
   * @param {*} pageNum  页码
   * @param {*} pageSize  条数
   */
   statisRanking(query){
    return this.ajax.post(`/tool/statis/ranking`,query)
  }

  /**
   * 用户访问量统计
   * @param {*} endDay   结束时间
   * @param {*} startDay  开始时间
   * @param {*} queryType  时间节点类型 1/2/3/4 时/天/月/年 
   */
   statisVisits(query){
    return this.ajax.post(`/tool/statis/visits`,query)
  }


  /**
   * 方块四个工具
   * @param {*} endDay   结束时间
   * @param {*} startDay  开始时间
   */
   toolsStatis(query){
    return this.ajax.post(`/tool/statis`,query)
  }



}
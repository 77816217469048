<template>
	<div class="youhong-reader" :id='options.type == "signature"?"youhong-reader":""'>
		<Menu  v-if="!(options.teamwork)"  ref="menu" :page='page' :showPrint="permissions.print == '1'" :scale="scale" @menuClick='menuClick' ></Menu>
		<div class="ofdBox" id='ofdBox' v-if='options.type != "video"'>
			<div class="thumbnail left-container" id='thumbnail'  v-if='options.type == "signature"'>
				<ul>
					<li v-for="item in thumbnail" :key="item.index"  >
						<img  style="background:#fff;margin:5px 0 5px;height:160px;"
						:src="item.data"  
						@click="changePage(item.index)"
						alt="" 
						:class="page.nowPage!=(item.index+1)?'':'img-active'"
						/>
					</li>
				</ul>
			</div>
			
			<div class="ofdContainer" :id='options.type == "signature"?"ofdContainer":""' >
				<!-- <VideoPlayer></VideoPlayer> -->
				<span class="saveDocument" v-if="options.teamwork && isApp" @click="save">保存</span>
				<Signature ref="viewer"  @onInited="onOFDViewer" @getPage='getPage' @getScaleX='getScaleX' v-if='options.type == "signature"'></Signature>
				<PcOFDReader ref="viewer" v-if='!isApp '  @loadEnd='loadEnd'  @onInited="onOFDViewer" @getPage='getPage' @getScaleX='getScaleX'></PcOFDReader>
				<MobileOFDReader ref="viewer" v-else @onInited="onOFDViewer"></MobileOFDReader>
				
			</div>
			<div class="right-contaner" v-if='options.type == "signature"'>
				<signImg 
				:nowPage='page.nowPage'
				:ScaleX='scale'
				></signImg>
			</div>
			<!-- 手写签批 -->
			<!-- 暂时隐藏 -->
			<div class="right-signNature-contaner" v-if='options.teamwork=="lalala"'>
				<h1  class="title">签名选项</h1>
				<div v-if='signUrl' class="right-signNature-wrap" >
					<div class="one"  @mousedown='dragstart'>
						<p>签名</p>
						<img :src="signUrl" alt="">
					</div>
					<div>
						<p><i class="el-icon-edit" @click="openSmoothSignature"></i></p>
						<p><i class="el-icon-delete" @click="signUrl = null"></i></p>
					</div>
				</div>
				<el-button v-else class="signBtn" @click="openSmoothSignature" size='small'>添加签名</el-button>
			</div>
			
		</div>
		
		<WaterMark :inputText="userInfo" v-if="JSON.stringify(permissions)!='{}'&&showWather"></WaterMark>
	
		<!-- 画区域遮掩 -->
		<ShadeArea   ref='shade' v-if="options.type=='edit'  &&options.template=='false' "  :scale="scale" :nowPage='page.nowPage'></ShadeArea>

		
		<SmoothSignatureVue v-if='options.teamwork' ref='SmoothSignatureVue' @changeSign='changeSign'></SmoothSignatureVue>
		<signJs v-if='options.teamwork' :nowPage='page.nowPage' :scale='scale' ref='signJs'></signJs>

		<VideoPlayer v-if='options.type == "video"' ref='VideoPlayer' :ticket='options.ticket'></VideoPlayer>
	</div>
</template>

<script>
import { getQueryParams } from "./utils/query.js"

import { Store } from '@lk/cache'
import { shareAPI, authAPI, userAPI ,gatherAPI , toolUploadAPI} from '@lk/api'
import MobileOFDReader from "./components/OFDReader-mobile.vue";
import PcOFDReader from "./components/OFDReader-pc.vue";
import WaterMark from "./components/WaterMark.vue";
import ShadeArea from './components/shade.vue'
import Menu from './components/Menu.vue'
import VideoPlayer from './videoPlayer/Index.vue'
export default {
	components: {
		WaterMark,
		PcOFDReader,//pc
		MobileOFDReader,//mobile
		ShadeArea,//画区域遮掩
		Menu,
		VideoPlayer
	},
	data() {
		return {
			showWather:false,
			//阅读环境参数
			options: {
				shareId: null,
				token: null,
				ticket: null,
				type:null,//edit是编辑模式，可以画掩码区域  reader是阅读模式，不可以画掩码
				expires:null,
				tool:false,//是否是工具阅读（小程序），用于如何获取lisense
			},
			//当前阅读的总数和页码
			page:{
				pageNum:0,
				nowPage:0
			},

			//分享信息
			shareInfo: {},

			//权限信息
			permissions: {},

			//用户信息
			userInfo: {},
			// 放大缩小倍数
			zoom:0,

			leftMenu_width:0,//左侧菜单宽度
			// docleft:0,//公章距离左侧文档边缘的距离

			index :0,
			activeName:'second',

			zhang:true,
			scale:1,
			thumbnail:[],// 缩略图
			widthX:0,
			positionMes:[],
			position:null,
			sealList:[],//印章图片列表
			pageNumber:0,
			positionX:0,
			positionY:0,
			num:1,
			signNameShow:false,
			signUrl:null
			// scaleX:0
		
		};
	},
	computed:{
		//是否是APP环境
		isApp(){
			return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
		}
	},
	created(){
		
		//获取阅读参数
		this.options.shareId = getQueryParams("id")
		this.options.token = getQueryParams("token")
		this.options.ticket = getQueryParams("ticket")
		this.options.type = getQueryParams("type");
		this.options.expires = getQueryParams('expires')
		this.options.template = getQueryParams('template')
		this.options.type = getQueryParams('video') == "true" ? 'video' :this.options.type;
		this.options.teamwork=getQueryParams("model")=='teamwork' //协同标识
		this.options.tool = getQueryParams("tool") == "true" ? true:false

		if(getQueryParams("type") == 'edit'){
			if(document.getElementsByClassName('scroll-container')[0]){
				document.getElementsByClassName('scroll-container')[0].style.webkitUserSelect = 'none'
			}
		}

		//填充环境数据
		if(this.options.token){
			Store.put("access_token", this.options.token);
		}
		if(this.options.expires){
			Store.put("expires", this.options.expires);
		}

		//分享阅读下，需要处理权限
		if(this.options.shareId&& this.options.type != "signature"){
			//加载用户信息
			this.loadUserInfo()

			//加载分享信息
			this.loadShareInfo()
		}

		if(this.options.type != 'video'){
			//加载权限信息
			this.loadPermissions()
	
		}

		if(this.options.type == 'signature'){
			this.getSignList();
		}

		if(this.options.tool){
			this.getToolsLicense()
		}else{
			Store.put('lic', 'y6rlcrvfpkeqw4232cb8e69b2f9ada8430be25b6ecdee')
		}
	},
	methods: {
		// 获取工具license
		getToolsLicense(){
			if(!Store.get('lic')){
				toolUploadAPI.getFrontEndLicense({dictCode:16}).then(res=>{
					const time = new Date().getTime() + 1000 * 60 * 60 * 24;
						let expires = new Date();
						expires.setTime(time);
					if(res.code==0){
						Store.put('lic', res.data.value, { expires: expires })
					} else {
						Store.put('lic', '1234567', { expires: expires })
					}     
				})
			}
		},
		// 触发组件中的js,避免App页面代码过于繁重
		dragstart(){
			let id =  Date.parse(new Date());
			this.$refs['signJs'].moveSeal(this.signUrl,id)
		},
		// 改变手写签名
		changeSign(url){
			this.signUrl = url;
		},
		// 打开手写签批
		openSmoothSignature(){
			this.$refs['SmoothSignatureVue'].dialogVisible = true;
			setTimeout(()=>{
				this.$refs['SmoothSignatureVue'].init();
			})
		},
		// 获取印章
		getSignList(){
			gatherAPI.getSeal({
				pageNum:1,
				pageSize:100
			}).then(res=>{
				if(res.code ==0){
					this.sealList = res.data;
				}else{
					this.$message.error(res.message)
				}
			}).catch(res=>{
				this.$message.error(res.message)
			})
		},
		//加载分享信息
		loadShareInfo(){
			shareAPI.detailByUrl(this.options.shareId).then(({code, data}) => {
				if(code == 0){
					this.$refs['menu'].viewer.showFiles = data.resources;
					this.$refs['viewer'].resources = data.resources[0];
				}else{
					this.$message.error("分享信息加载失败")
				}
			})
		},

		// 获取总页数和当前页数
		getPage(page){
			if(page.pageNum){
				this.page.pageNum = page.pageNum;
			}else{
				this.page.nowPage = page.nowPage;
			}
		},
	
	
		//加载权限信息
		loadPermissions(){
			authAPI.getPermissionsByReadTicket("/testreader", this.options.ticket).then(data => {
				this.permissions = data;
				if(data.water!='0'&&data.water){
				shareAPI.getShareWatherDetail(data.water).then(res=>{
					if(res.code==0){
						this.userInfo.isDynamic = data.isDynamic
						let {color,text,rotate,fontSize,fontFamily} = res.data
						this.userInfo.loginUserName = text
						this.userInfo.font = `normal ${fontSize}px ${fontFamily}`
						this.userInfo.fillStyle = color
						this.userInfo.rotate = rotate
						this.showWather = true
					} else {
						this.$message.error('获取水印信息失败')
					}
				})
				} else if(data.isDynamic=='1'){
					this.userInfo.isDynamic = data.isDynamic
					this.userInfo.loginUserName  = decodeURI(getQueryParams('username'))
					this.userInfo.font = 'normal 18px Microsoft Yahei'
					this.userInfo.fillStyle = '#a5a6a7'
					this.userInfo.rotate = 30
					this.showWather = true
				}
				
			})
		},
		// 缩略图切换页面
		changePage(index){
			this.$refs["viewer"].changePage(index);
		},

		// 撤销
		doBack(type){
			this.$refs["viewer"].doBack(type);
		},
		
		//加载用户信息
		loadUserInfo(){
			userAPI.getInfo().then(({code, data}) => {
				if(code == 0){
				this.userInfo = data
				}
			})
		},
		// 签章tab切换
		tabChange(id){
			id == 0 ? this.zhang = true:this.zhang =false;
		},

		// 获取原始ofd的宽度
		getScaleX(widthX){
			if(!(getQueryParams("model")=='teamwork')){
				this.$refs.menu.showScaleNumber = (widthX*100).toFixed(2)
			}
			if(this.$refs.shade ){
				// console.log("this.$refs.shade",this.$refs.shade)
				this.$refs.shade.echoScale = widthX;
			}
			this.scale = widthX;
		},
		
		// 过滤数组
		filterArr(arr){
			if(arr.length == 0){
				arr.push(this.position)
			}else{
				let sealIndex = arr.findIndex((item) => {
					return item.index === this.position.index
				});
				if(sealIndex == -1){
					arr.push(this.position)
				}else{
					arr.splice(sealIndex,1,this.position)
				}
			}
		},
		menuClick(type,value){
			let query={}
			switch(type){
				case 'scale':
					if(value == 0){
						this.scale = this.scale + 0.25;
					}else{
						if(this.scale == 0){
						break;
						}else{
						this.scale = this.scale - 0.25;
						}
					}
					this.$refs['viewer'].setScale(this.scale)
				break;
				case 'setpage':
					this.$refs['viewer'].gotoPage(value)
				break;
				case 'getTicketChange':
					query.documentId= value.documentId || value.id,
					query.documentName= value.documentName,
					query.documentRootId=value.documentId,
					query.shareId= value.shareId
					shareAPI.getReaderUrl(query)
					.then(({ code, data }) => {
						if(code==0){
						this.change(data.ticket);
						}
					})
				break;
				case 'setpageView':
					this.$refs['viewer'].setpageView(value)
				break;
				case 'print':
					if(JSON.stringify(this.permissions)=='{}'||(this.permissions.print=='1'&&this.permissions.printCount=='0')){
						this.$refs['viewer'].showPrint()
					} else if(this.permissions.print=='1'&&this.permissions.printCount!='0'){
						let shareId =  getQueryParams("shareIds")
						let documentId =  getQueryParams("documentId")
						let documentRootId =  getQueryParams("documentRootId")
						let phoneNumber =  getQueryParams("phoneNumber")
						shareAPI.checkUserSharePermission({
						shareId,documentId,documentRootId,permissionName:'printCount',phoneNumber
						}).then(res=>{
						if(res.code==0){
							this.$refs['viewer'].showPrint()
						} else {
							this.$message.error(res.message)
						}
						})
					}else{
						this.$message.error('当前文档没有打印权限')
					}
				break;
				case 'download':
					if(this.permissions.print=='1'){
						this.$refs['viewer'].downLoad(this.options.ticket)
					}else{
						this.$message.error('当前文档没有下载权限')
					}
				break;
			}
		},
		onOFDViewer(thumbnail) {
			console.log("阅读器组件初始化完成：");
			//填充环境数据
			if(this.options.token){
				Store.put("access_token", this.options.token);
			}
			if(this.options.expires){
				Store.put("expires", this.options.expires);
			}
			if (this.options.ticket) {
				this.$refs["viewer"].openByTicket(this.options.ticket);
			}
			this.thumbnail = thumbnail;
		},
		change(ticket) {
			this.$refs["viewer"].openByTicket(ticket);
		},
		loadEnd(){
			if(this.permissions.copy == '1'){
				document.getElementById('ofdBox').classList.add('userSelect')
			}
			if(this.options.type == 'edit'){
				console.log("edit","edit");
				this.$refs['shade'].loadCanvas();
			}
			// this.$refs['drawLine'].loadCanvas();
		},
		save(){
			this.$refs["viewer"].save()
		}
	}
}
</script>

<style>
html,
body,
.youhong-reader {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* display: flex;
  flex-direction: column; */
}



ul,li{
  margin: 0;
  padding: 0;
  list-style: none;
}
.youhong-reader .main{
  width: 100%;
  /* height: 100%; */
}
.signBtn{
	position: fixed;
	top: 20%;
	right: 5%;
}

.ofdBox{
  display: flex;
  width: 100%;
  height: 100%;
  
  /* background: #efefef; */
  /* overflow: scroll; */
}
.mask-button{
    position: fixed;
    top: 60px;
    right: 10px;
}

.ofdContainer{
  width: 100%;
}
#ofdContainer{
  width: 60%;
}


.zhang01{
  width: 60mm;
  height:40mm;
  background: rgba(0, 0, 0, 0.4);
  margin: 10px auto;
}
#ofdContainer{
 margin-left: 0;
}
.zhang02{
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.4);
  margin: 10px auto;
}
.mode-continuity .page-group.page {
    margin: 0 0px!important;
}
.mode-single .scroll-container{
  padding: 15px 0!important;
}
.left-container{
  height: 100%;
  overflow-y: auto;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}
.right-signNature-contaner{
	background: #fff;
	padding: 15px;
	box-sizing: border-box;
	width: 20%;
	height: 100vh;
}
.right-signNature-contaner div.right-signNature-wrap{
	border: 1px solid #9bb8da;
	height: 76px;
	background: #f4f5f9;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;
	cursor: move;
}
.right-signNature-contaner div.right-signNature-wrap div.one{
	border: 1px dashed #adadb6;
    height: calc(100% - 22px);
    -ms-flex: auto;
    flex: auto;
    margin: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 24px;
	position: relative;
}
.right-signNature-contaner div.right-signNature-wrap img{
	max-width: 140px;
    max-height: 36px;
}
.right-signNature-wrap .one  p{
	font-size: 8px;
    color: #4a7aab;
    letter-spacing: .16px;
    line-height: 24px;
    position: absolute;
    left: 10px;
    top: -8px;
}
.right-signNature-wrap  i{
	color: #4a7aab;
	cursor: pointer;
}
.right-signNature-contaner h1{
	font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0;
    color: #383e45;
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: center;
    padding: 21px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    position: relative;
    background-color: #fff;
	margin: 0;
	margin-bottom: 20px;
}
.right-signNature-contaner .el-button{
	color: #fff;
	min-width: 80px;
	min-height: 56px;
    font-size: 28px;
    line-height: 28px;
    background-color: #e5322d;
	outline: none;
	border: none;
}
.position-box{
  padding: 10px;
} 
.position-box input{
  height: 25px;
  margin-top: 10px;
  border: 1px solid #736363;
}
@media (min-width: 769px) {
  html, body, #app{
    background: rgb(82, 86, 89);
  }
  .ofdBox{
    margin-top: -50px;
  padding-top: 50px;
  box-sizing: border-box;
    background: rgb(82, 86, 89)!important;
  }
  .ofdContainer #XOFDWrapper{
    background: rgb(82, 86, 89)!important;
  }
  .document{
    background: rgb(82, 86, 89)!important;
  }
  .bottom.show{
    box-shadow: none!important;
  }
  
}
.userSelect .layout-text{
    -webkit-user-select: none!important;
}
.saveDocument{
	position: fixed;
	right: 5px;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	top: 20%;
	z-index: 999;
	font-size: 14px;
	color: #323233;
	text-align: center;
	box-sizing: border-box;
	padding-top: 9px;
	background: #fff;
	box-shadow: -1px 1px 5px #576b95;
}

</style>                                                                                                            
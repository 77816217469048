import BaseAPI from '../BaseAPI.js'

/**
 * 工具 上传文件服务 http://192.168.1.45:8092
 * 含：文件上传、文件删除、获取省市区、文件转换
 */
export default class ToolUploadAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/dict"

        // this.moduleName = "/admin" // 1205
    }

    /** 获取省市区列表 */
    getAreaList(query) {
        return this.ajax.get(`${this.moduleName}/area/select`, query)
    }

    /** 上传临时文件多文件 返回附加信息 */
    uploadMultiFile(query) {
        return this.ajax.post(`${this.moduleName}/file/upload/multi/temp`, query)
    }

    /** 删除上传的文件 */
    removeUploadFile(query) {
        return this.ajax.post(`${this.moduleName}/file/delete`, query)
    }

    /** 上传临时文件单文件 */
    uploadLinFile(query) {
        return this.ajax.post(`${this.moduleName}/file/upload/temp`, query)
    }

    /** 工具处理页面批量下载 */
    fileDownloadOpertate(query) {
        return this.ajax.get(`${this.moduleName}/file/download/tool/batch?ids=${query.ids}&uuid=${query.uuid}`)
    }

    /** 工具处理页面批量下载 */
    downloadFilesUrls(info) {
        const fileDownloadUrl = `/api/document/tool/batch`
        if(info.access_token) {
            return fileDownloadUrl + `?ids=${info.ids}&uuid=${info.uuid}&access_token=${info.access_token}`
        } else {
            return fileDownloadUrl + `?ids=${info.ids}&uuid=${info.uuid}`
        }
        
    }

    // 获取上传文件大小限制
    getUploadFileMaxByte() {
        return this.ajax.get(`/document/tool/size/max`, {})
    }

    // 上传检查md5
    checkFileMD5(arr) {
        return this.ajax.post(`${this.moduleName}/file/check/md5`, { fileList: arr })
    }

    // 分片上传检查接口
    fileChunkUploadGet(query) {
        return this.ajax.get(`/document/tool/chunk/upload`, query)
    }

    // 分片上传接口
    fileChunkUploadPost(query) {
        return this.ajax.post(`/document/tool/chunk/upload`, query)
    }

    // 分片合并
    fileChunkUploadMerge(query) {
        return this.ajax.put(`/document/tool/file/merge`, query)
    }


    // base64转id
    base64ConverId(query) {
        return this.ajax.post(`/document/tool/base64/upload`, query)
    }


    // 预览文件
    openPreviewFile(query) {
        return this.ajax.get(`/testreader/api/openPreviewFile`, query, {
            responseType: 'blob'
        })
    }

    // 预览文件
    openPreviewPdfFile(query) {
        return this.ajax.get(`/testreader/api/openPreviewFile`, query, {
            responseType: 'arraybuffer'
        })
    }

    // 链控下载
    lkDownloadFile(query, token) {
        return this.ajax.get(`/document/download?documentId=${query.documentId}&types=e&encryptPwd=null&access_token=${token}`, {}, {
            responseType: 'arraybuffer'
        })
    }
    
    // 获取登录配置项
    getSystemLoginConfig(query) {
        return this.ajax.get(`${this.moduleName}/sysConfig/login`, query)
    }
    // 更新登录配置项
    updateSystemConfigLogin(query) {
        return this.ajax.put(`${this.moduleName}/sysConfig/login`, query)
    }

    // 获取改版之后文档配置项
    getDictDataListByType(query) {
        return this.ajax.get(`${this.moduleName}/dict/data/getDictDataListByType`, query)
    }
    // 获取改版之后文档配置项
    updateSysPublicConfig(query) {
        return this.ajax.post(`${this.moduleName}/dict/data/updateSysPublicConfig`, query)
    }

    // 获取uuid过期时间
    getResultExpireTime() {
        return this.ajax.get(`/sysConfig/prop/biz.result.expireTime` )
    }
    // 查看前端阅读器linces设置
    getFrontEndLicense(query) {
        return this.ajax.get(`${this.moduleName}/dict/data/${query.dictCode}`)
    }
     // 设置前端阅读器linces设置
    setFrontEndLicense(query) {
        return this.ajax.put(`${this.moduleName}/dict/data`,query)
    }


     // 资源上传新接口
    resourcesUploadFile(file) {
        let formData = new FormData();
        formData.append('file',file)
        return this.ajax.post(`/document/static/upload`,formData)
    }

    // 转换记录
    getTransRecords(query) {
        return this.ajax.post(`/tool/task/page/list`,query)
    }
    

    // 登录同步转换记录
    updateTaskOwner() {
        return this.ajax.put(`/tool/task/update/task/owner`)
    }

    // 转换记录取消任务
    cancelResultTask(query) {
        return this.ajax.delete(`/tc/result/cancelTask`,query)
    }
    
    // 前端处理工具添加转换记录
    addFrontTask(query) {
        return this.ajax.post(`/tool/task/addFrontTask`,query)
    }

    // 判断license是否过期
    verifyLicense(){
        return this.ajax.get(`/tc/license/verify`)
    }

    // 生成智能抠图蒙版
    toIntermediateMask(file){
        let formData = new FormData();
        formData.append('file',file)
        return this.ajax.post(`/tc/intermediate/mask`,formData)
    }
}
<template>
<div>
  <div class="edit-box">
    <button id="cancel" @click="back">撤销</button>
    <button id="clear" @click="clear">清空</button>
    <button id="clear" @click="commit">保存</button>
  </div>
</div>

</template>
<script>
import { shareAPI ,gatherAPI } from '@lk/api'
import { getQueryParams } from "../utils/query.js"
import {MessageBox } from 'element-ui';
export default {
    props:{
      nowPage:{
        type:Number,
        default:1
      },
      scale:{
        type:Number,
        default:1
      }
    },
    watch: {
      nowPage() {
        console.log("nowPage", this.nowPage)
        console.log("scale",this.scale);
        this.page = document.getElementsByClassName('page')[this.nowPage-1];
        if(this.layers.length != 0 && document.getElementsByClassName('page')[this.nowPage-1]){
          this.backShow(this.nowPage)
        }
      }
    },
    data () {
      return {
        page:null,
        dragging:false,
        // startX, startY 为鼠标点击时初始坐标
        startX:0,
        startY:0,
        // diffX, diffY 为鼠标初始坐标与 ebox 左上角坐标之差，用于拖动
        diffX:0,
        diffY:0,
        header:null,
        main:null,
        layers:[],
        id:0,//当前操作的id
        current:{},
        echoLayers:[],
        isEcho:false,//是否回显
        echoScale:1 ,//回显时缩放,
        defaultScale: 0.75
      }
    },
    mounted(){
      this.isEcho =  Boolean(getQueryParams('echo'))
      if(this.isEcho){
        this.getShadeAreaById()
      }
    },
    created(){
      this.shadeAreaId = getQueryParams('shadeAreaId')
    },
    methods:{
      getShadeAreaById(){
        console.log("getShadeAreaById:", );
        shareAPI.getShadeAreaById(this.shadeAreaId).then(res => {
          if(res.code == 0){
            this.layers  = []
            this.layers  =  res.data
            // res.data.forEach(item=>{
            //     this.layers.push({
            //       areaId: item.areaId,
            //       areaName: item.areaName,
            //       height: Number(item.height),
            //       left: Number(item.left),
            //       pageIndex: Number(item.pageIndex),
            //       top: Number(item.top),
            //       width:Number(item.width)
            //     });
            // })
            // console.log("layers",JSON.stringify(res.data));
          }
        })
      },
      // 回显数据加载
      getTemplateEditor(){
        let templateId = getQueryParams("documentId");
        gatherAPI.getTemplateEditor(templateId).then(res=>{
          
          if(res.code == 0){
            this.isEcho = true;
            let scale = 1; let dpi = 72;
            res.data.forEach(item=>{
              this.echoLayers.push({
                tagId:item.tagId,
                pageIndex:item.pageIndex,
                left:Number(item.x)/25.4*dpi*scale,
                top:Number(item.y)/25.4*dpi*scale,
                width:Number(item.width)/25.4*dpi*scale,
                height: Number(item.height)/25.4*dpi*scale,
              })
             
            })
            // this.echoLayers = res.data;
          }else{
            this.$message.error(res.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      //回显
      backShow(index){
        console.log("index",index);
        let dpi = 72;
          let list = this.layers.filter(m => m.pageIndex === (index-1))
          let page = document.getElementsByClassName('page')[index-1];
          // 只添加一次结构
          if(page.lastElementChild.className == 'ebox'){
            return
          }else{
            list.map(item =>{
              let active_box = document.createElement("div");
              active_box.setAttribute('tabIndex',item.areaId)
              active_box.className = "ebox";
              active_box.style.position= 'absolute';
              active_box.innerText ='遮掩区域'
              
              let top = Number(item.top)/25.4*dpi*this.echoScale/this.defaultScale;
              let left = Number(item.left)/25.4*dpi*this.echoScale/this.defaultScale;
              let width = Number(item.width)/25.4*dpi*this.echoScale/this.defaultScale;
              let height = Number(item.height)/25.4*dpi*this.echoScale/this.defaultScale;
              console.log("top",top,"left",left,"width",width,"height",height,"scale",this.echoScale)
              item.top = top;
              item.left = left;
              item.width = width;
              item.height = height;
              active_box.style.top = top + 'px';
              active_box.style.left = left + 'px';
              active_box.style.width = width +'px';
              active_box.style.height = height +'px';
              let span = document.createElement('span');
              span.className = 'span'
              active_box.appendChild(span)
              page.appendChild(active_box);
              active_box = null;
            })
          }
      },
      loadCanvas(){
        if(document.getElementsByClassName('page').length!=0){
         
          this.header = document.getElementsByClassName('header-box')[0]
          this.main = document.getElementsByClassName('scroll-container')[0]
          let page = document.getElementsByClassName('page')[0]
          
          document.onmousedown = this.mousedown;
          document.onmouseup = this.mouseup;
          document.onmousemove = this.mousemove;
          this.page = page;
          this.backShow(this.nowPage)
        }
        
      },
      // 撤销
      back(){
        if(this.page.lastElementChild.className == 'ebox'){
          let id = this.page.lastElementChild.getAttribute('tabindex')
          this.deleteLayers(id)
          this.page.removeChild(this.page.lastElementChild)
        }
      },
      // 清空
      clear(){
        this.layers = [];
        let seal = document.getElementsByClassName('ebox');
        while (seal.length!=0) {
          seal.forEach(item=>{
            item.remove();
          })
        }
      },
      // 保存
      commit(){
        if(this.layers.length != 0){
            let dpi = 72;
            console.log("this.scale",this.scale);
            // 深拷贝
            let arr = JSON.parse(JSON.stringify(this.layers));
            arr.forEach(item=>{
              item.left = (item.left/this.scale/dpi*25.4*this.defaultScale).toFixed(3)
              item.top = (item.top/this.scale/dpi*25.4*this.defaultScale).toFixed(3)
              item.width = (item.width/this.scale/dpi*25.4*this.defaultScale).toFixed(3)
              item.height = (item.height/this.scale/dpi*25.4*this.defaultScale).toFixed(3)
            })
            
            let query = {
              documentId:getQueryParams("documentId"),
              rgb:'113 122 123',
              shadeAreaId:getQueryParams("shadeAreaId"),
              shadeAreas:arr
            }

            MessageBox.confirm('设置遮掩区域文件不能复制, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(()=>{
                shareAPI.addShadeArea(query).then(res=>{
                  if(res.code == 0){
                    // 为了给链控iframe通讯
                    window.parent.postMessage({
                      cmd: 'copy',
                      params: {
                        info: true,
                        query
                      }
                    }, '*')
                    this.$message.success('操作成功')
                  }else{
                    this.$message.error(res.message)
                  }
                }).catch(err=>{
                  this.$message.error(err.message)
                })
            })
        }else{
            shareAPI.deleteShadeAreaById(getQueryParams("shadeAreaId")).then(res => {
              if(res.code == 0){
                // 为了给链控iframe通讯
                window.parent.postMessage({
                  cmd: 'clearShadeAreaId',
                  params: {
                    reset: true
                  }
                }, '*')
                this.$message.success('操作成功')
              }else{
                this.$message.error(res.message)
              }
            }).catch(err=>{
              this.$message.error(err.message)
            })
        }

      
        

      },
      // 添加属性
      filterLayers(form,type){
        this.layers.forEach(item=>{
          if(item.areaId == form.id ){
            if(type == 'position'){
              item.left = form.x;
              item.top = form.y;
            }else{
              item.width = form.width;
              item.height = form.height;
            }
          }
        })
      },
      mousedown(e){
        this.page = document.getElementsByClassName('page')[this.nowPage-1]

        this.startX = e.pageX - this.page.offsetLeft +this.main.scrollLeft;
        this.startY = e.pageY - this.page.offsetTop - this.header.offsetHeight +this.main.scrollTop;
        let _this = this;
        // 如果鼠标在 box 上被按下
        if(e.target.className == 'ebox') {
          
            // 允许拖动
            _this.dragging = true;

            // 设置当前 ebox 的 id 为 moving_box
            if(document.getElementById("moving_box") !== null) {
                document.getElementById("moving_box").removeAttribute("id");
            }

            e.target.id = "moving_box";
          
            // 计算坐标差值
            _this.diffX = _this.startX - e.target.offsetLeft;
            _this.diffY = _this.startY - e.target.offsetTop;
 
        }else if(e.target.className == 'span'){
          // 拖动右下角，改变框的宽高
            
            // 设置当前 span 的 id 为 change_box
            if(document.getElementById("change_box") !== null) {
                document.getElementById("change_box").removeAttribute("id");
            }
            e.target.id = "change_box";

        }else {
          let id = new Date().getTime()
          // 在页面创建 box
          let active_box = document.createElement("div");
          active_box.setAttribute('id','active_box')
          active_box.setAttribute('tabIndex',id)
          active_box.id = "active_box";
          active_box.className = "ebox";
          active_box.style.position= 'absolute';
          active_box.innerText ='遮掩区域'
          active_box.style.top = this.startY + 'px';

          let span = document.createElement('span');
          span.className = 'span'
          active_box.appendChild(span)

          active_box.style.left =  this.startX + 'px';
          this.page.appendChild(active_box);
          active_box = null;
          this.id = id;
          this.layers.push({
            areaId:id,
            left:this.startX,
            areaName:'遮掩',
            top:this.startY,
            pageIndex:(this.nowPage-1),
            width:0,
            height:0
          })
    
        }
      },
      mouseup(){
        this.page = document.getElementsByClassName('page')[this.nowPage-1]
         // 禁止拖动
        this.dragging = false;
        if(document.getElementById("active_box") !== null) {
          var ab = document.getElementById("active_box");
          ab.removeAttribute("id");
          // 如果长宽均小于 3px，移除 box
          if(ab.offsetWidth < 3 || ab.offsetHeight < 3) {
            let id = ab.getAttribute('tabindex')
            this.deleteLayers(id)
            this.page.removeChild(ab);
          }
        }
        // 改变宽高
        if(document.getElementById('change_box') !== null ){
          let ab = document.getElementById('change_box');
          ab.removeAttribute('id')
        }
      },
      mousemove(e){
        this.page = document.getElementsByClassName('page')[this.nowPage-1]
        // 更新 box 尺寸
        if(document.getElementById("active_box") !== null) {
            let ab = document.getElementById("active_box");
            let id = ab.getAttribute('tabindex')
            let endX = e.pageX - this.page.offsetLeft + this.main.scrollLeft;
            let endY = e.pageY - this.page.offsetTop - this.header.offsetHeight +this.main.scrollTop;
            ab.style.width = endX-  this.startX + 'px';
            ab.style.height = endY -  this.startY + 'px';
            let form = {
              id:id,
              width:endX - this.startX,
              height:endY - this.startY
            }
            this.filterLayers(form,'size')
        }
        if(document.getElementById("change_box") !== null && !this.dragging){
          let span = document.getElementById("change_box");
          let id = span.parentNode.getAttribute('tabindex')
          let endX = e.pageX - this.page.offsetLeft + this.main.scrollLeft;
          let endY = e.pageY - this.page.offsetTop - this.header.offsetHeight +this.main.scrollTop;
          let startX = span.parentNode.offsetLeft;
          let startY = span.parentNode.offsetTop;
          span.parentNode.style.width = endX - startX + 'px';
          span.parentNode.style.height = endY - startY + 'px';
          let form = {
            id:id,
            width:endX - startX,
            height:endY - startY
          }
          this.filterLayers(form,'size')
        }
           
        // 移动，更新 box 坐标
        if(document.getElementById("moving_box") !== null && this.dragging) {
            let endX = e.pageX - this.page.offsetLeft + this.main.scrollLeft;
            let endY = e.pageY - this.page.offsetTop - this.header.offsetHeight +this.main.scrollTop;
            
            let mb = document.getElementById("moving_box");
            let id = mb.getAttribute('tabindex')
            mb.style.top = endY -  this.diffY + 'px';
            mb.style.left = endX - this.diffX + 'px';
            let form = {
              id:id,
              x:endX - this.diffX,
              y:endY -  this.diffY
            }
            this.filterLayers(form,'position')
        }
      },
      // 获取当前设置的区域信息
      getCurrent(id){
        let obj = {}
        this.layers.forEach(item=>{
          if(item.areaId == id){
            obj = item;
          }
        })
        return obj
      },
      // 当框小于3时，从数组里去掉，不保存
      // 撤销时 从数组里去掉
      deleteLayers(id){
        this.layers.forEach((item,index)=>{
          if(item.areaId == id){
            this.layers.splice(index,1);
          }
        })
      }
    },
}
</script>
<style >
.edit-box{
  position: fixed;
  top:0;
  right: 10px;
  z-index: 999;
} 
.edit-box button{
   background:#67c23a ;
   border:none;
   outline: none;
   margin-right: 10px;
   margin-top: 10px;
     padding:4px 10px;
  font-size: 14px;
  color: #fff;
}.edit-box button:nth-child(1){
  background: #ccc;
  color: #000;
}
.edit-box button:nth-child(2){
  background: #fff;
  color: #000;
}
.ebox{
  width: 0px;
  height: 0px;
  position: absolute!important;
  cursor: move;
  top: 0;
  left: 0;
  background: rgba(212,216,216,0.5)!important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.ebox .span{
  position: absolute;
  bottom: 0;
  right: 0;
  border: 3px solid;
  border-color: transparent #000 #000 transparent;
  cursor: nw-resize;
}
.ebox .deleteBtn{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 15px;
  height:15px;
  border:none;
}
.deleteBtn img{
    width: 15px;
    height:15px
  }
</style>


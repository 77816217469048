<template>
  <div id="app">
    <div class="video-content">
      <player-video :volume="volume" ref="video" v-if='src' :src="src"></player-video>
    </div>
    <div class="button-group">
      <!-- <el-button class="primary" size='small' @click="playVideo">开始视频</el-button> -->
      <el-button class="primary" size='small'  @click="stopVideo">暂停视频</el-button>
      <!-- <el-button class="primary" size='small'  @click="reloadVideo">重新加载</el-button> -->
      <!-- <el-button class="primary" size='small'  @click="forwardVideo">视频快进</el-button> -->
      <!-- <el-button class="primary" size='small'  @click="backVideo">视频后退</el-button> -->
      <el-button class="primary" size='small'  @click="volumeUpVideo">增大音量</el-button>
      <el-button class="primary" size='small'  @click="volumeDownVideo">降低音量</el-button>
    </div>
  </div>
</template>
<script>
  import PlayerVideo from "./PlayerVideo.vue";

  export default {
    components: {
      PlayerVideo,
    },
    props:{
      ticket:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        volume: 0.5,
        src:null,
      };
    },
    computed: {
      video() {
        return this.$refs.video;
      },
    },
    mounted(){
      let url = '/prod-api/fs/getFileStream?ticket=' + this.ticket;
      this.src = url;
    },
    methods: {
      // 父类组件调用子组件方法，触发播放器功能
      stopVideo() {
        this.video.stop();
      },
      playVideo() {
        this.video.play();
      },
      reloadVideo() {
        this.video.reload();
      },
      forwardVideo() {
        this.video.forward();
      },
      backVideo() {
        this.video.back();
      },
      fullScreenVideo() {
        this.video.fullScreen();
      },
      screenVideo() {
        this.video.exitScreen();
      },
      volumeUpVideo() {
        this.video.volumeUp();
      },
      volumeDownVideo() {
        this.video.volumeDown();
      },


    },
    beforeCreate() {
      this.volume = window.localStorage.volume;
    },
  };
</script>
<style>
 .video-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    .button-group {
    display: flex;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
</style>

import BaseAPI from '../BaseAPI.js'

/**
 * 管理端3.0、工具平台端接口
 */
export default class ManageAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = ''
        this.opName = "/operation" // 轮播图
        this.toolName = "/tool" // 工具
        this.dictModuleName = '/dict' // 上传文件
        this.orgModuleName = "/usercenter" // 租户管理
        this.materialName = '/material'//素材库
    }

    /**
     * 轮播图管理 获取列表
     * @param {*} query 
     * @returns 
     */
    getRotationList(query) {
        return this.ajax.get(`${this.opName}/advert/page`, query)
    }

    /**
     * 删除轮播图
     * @param {*} id
     * @returns 
     */
    removeRotation(id) {
        return this.ajax.delete(`${this.opName}/advert/${id}`)
    }

    /**
     * 添加轮播图
     * @param {*} query 
     * @returns 
     */
    addRotation(query) {
        return this.ajax.post(`${this.opName}/advert`, query)
    }

    /**
     * 轮播图详情
     * @param {*} id 
     * @returns 
     */
    rotationDetail(id) {
        return this.ajax.get(`${this.opName}/advert/${id}`)
    }

    /**
     * 编辑轮播图
     * @param {*} query 
     * @returns 
     */
    editRotation(query) {
        return this.ajax.put(`${this.opName}/advert`, query)
    }

    /** 添加cms */
    addToolsTopic(query) {
        return this.ajax.post(`${this.toolName}/topic/add`, query)
    }

    /** 获取后台cms详情 */
    getToolsTopicDetail(topicId) {
        return this.ajax.post(`${this.toolName}/topic/detail/${topicId}`)
    }

    /** 获取后台cms列表 */
    getToolTopicList(query) {
        return this.ajax.post(`${this.toolName}/topic/back/page/List`, query)
    }

    /** 获取前台cms列表 */
    getFontTopicList(query) {
        return this.ajax.post(`${this.toolName}/topic/front/list`, query)
    }

    /** 修改后台cms状态 */
    updataTopicStates(query) {
        return this.ajax.put(`${this.toolName}/topic/change/state/${query.id}/${query.state}`)
    }

    /** 删除cms */
    delTopicStates(query) {
        return this.ajax.post(`${this.toolName}/topic/delete`, query)
    }

    /** 删除cms */
    editToolsTopic(query) {
        return this.ajax.put(`${this.toolName}/topic/edit`, query)
    }

    /**
     * 获取工具分类列表
     * @param {*} query 
     * @returns 
     */
    getTooltypeList(query) {
        return this.ajax.get(`${this.toolName}/category/page`, query)
    }

    /**
     * 添加工具分类
     * @param {*} query 
     * @returns 
     */
    addTooltype(query) {
        return this.ajax.post(`${this.toolName}/category`, query)
    }

    /**
     * 工具分类详情
     * @param {*} id 
     * @returns 
     */
    tooltypeDetail(id) {
        return this.ajax.get(`${this.toolName}/category/${id}`)
    }

    /**
     * 修改工具分类
     * @param {*} query 
     * @returns 
     */
    editTooltype(query) {
        return this.ajax.put(`${this.toolName}/category`, query)
    }

    /**
     * 删除工具分类
     * @param {*} id 
     * @returns 
     */
    removeTooltype(id) {
        return this.ajax.delete(`${this.toolName}/category/${id}`)
    }

    /**
     * 获取最受欢迎列表
     * @param {*} query 
     * @returns 
     */
    getMostpopularList(query) {
        return this.ajax.get(`${this.toolName}/popular/page`, query)
    }

    /**
     * 添加最受欢迎
     * @param {*} query 
     * @returns 
     */
    addMostpopular(query) {
        return this.ajax.post(`${this.toolName}/popular`, query)
    }

    /**
     * 修改最受欢迎
     * @param {*} query 
     * @returns 
     */
    editMostpopular(query) {
        return this.ajax.put(`${this.toolName}/popular`, query)
    }

    /**
     * 删除最受欢迎
     * @param {*} id 
     * @returns 
     */
    removeMostpopular(id) {
        return this.ajax.delete(`${this.toolName}/popular/${id}`)
    }

    /**
     * 获取意见反馈信息
     * @param {*} query 
     * @returns 
     */
    getFeedBackList(query) {
        return this.ajax.post(`/operation/feedback/page`, query)
    }

    /**
     * 是否受理意见
     * @param {*} query 
     * @returns 
     */
    feedBackHandle(query) {
        return this.ajax.post(`/operation/feedback/handle`, query)
    }

    /**
     * 添加工具公告
     * @param {*} query 
     * @returns 
     */
    addNotify(query) {
        return this.ajax.post(`/operation/notify/add`, query)
    }

    /**
     * 工具公告列表
     * @param {*} query 
     * @returns 
     */
    pageNotify(query) {
        return this.ajax.post(`/operation/notify/page`, query)
    }

    /**
     * 删除工具公告
     * @param {*} query 
     * @returns 
     */
    delNotify(id) {
        return this.ajax.get(`/operation/notify/delById/${id}`)
    }

    /**
     * 删除工具公告
     * @param {*} query 
     * @returns 
     */
    editNotify(query) {
        return this.ajax.post(`/operation/notify/edit`, query)
    }

    /**
     * 获取工具列表
     * @param {*} query 
     * @returns 
     */
    getToolList(query) {
        return this.ajax.get(`${this.toolName}/tool/page`, query)
    }

    /**
     * 工具排序
     * @param {*} query 
     * @returns 
     */
    toToolSort(query) {
        return this.ajax.post(`${this.toolName}/popular/sort`, query)
    }

    /**
     * 获取工具下拉框列表
     * @param {*} query 
     * @returns 
     */
    getSelectToolList() {
        return this.ajax.get(`${this.toolName}/popular/tools`)
    }

    /**
     * 添加工具
     * @param {*} query 
     * @returns 
     */
    addToolData(query) {
        return this.ajax.post(`${this.toolName}/tool`, query)
    }

    /**
     * 编辑工具
     * @param {*} query 
     * @returns 
     */
    editToolData(query) {
        return this.ajax.put(`${this.toolName}/tool`, query)
    }

    /**
     * 获取工具详情 管理端
     * @param {*} id 
     * @returns 
     */
    toolDetailInfo(id) {
        return this.ajax.get(`${this.toolName}/tool/${id}`)
    }

    /**
     * 删除工具
     * @param {*} id 
     * @returns 
     */
    removeToolData(id) {
        return this.ajax.delete(`${this.toolName}/tool/${id}`)
    }

    /**
     * 获取版本列表
     * @param {*} query 
     * @returns 
     */
    getVersionList(query) {
        return this.ajax.get(`${this.toolName}/version/page`, query)
    }

    /**
     * 新增版本
     * @param {*} query 
     * @returns 
     */
    addVersion(query) {
        return this.ajax.post(`${this.toolName}/version`, query)
    }

    /**
     * 编辑版本
     * @param {*} query 
     * @returns 
     */
    editVersion(query) {
        return this.ajax.put(`${this.toolName}/version`, query)
    }

    /**
     * 获取版本详情 管理端
     * @param {*} id 
     * @returns 
     */
    getVersionDetail(id) {
        return this.ajax.get(`${this.toolName}/version/${id}`)
    }

    /**
     * 删除版本
     * @param {*} id 
     * @returns 
     */
    removeVersion(id) {
        return this.ajax.delete(`${this.toolName}/version/${id}`)
    }

    /**
     * 获取安装包列表
     * @param {*} query 
     * @returns 
     */
    getInstallPackageList(query) {
        return this.ajax.get(`${this.toolName}/package/ls`, query)
    }

    /**
     * 添加安装包
     * @param {*} query 
     * @returns 
     */
    addInstallPackage(query) {
        return this.ajax.post(`${this.toolName}/package`, query)
    }

    /**
     * 编辑安装包
     * @param {*} query 
     * @returns 
     */
    editInstallPackage(query) {
        return this.ajax.put(`${this.toolName}/package`, query)
    }

    /**
     * 获取安装包详情 管理端
     * @param {*} id 
     * @returns 
     */
    getInstallPackageDetail(id) {
        return this.ajax.get(`${this.toolName}/package/${id}`)
    }

    /**
     * 删除安装包
     * @param {*} id 
     * @returns 
     */
    removeInstallPackage(id) {
        return this.ajax.delete(`${this.toolName}/package/${id}`)
    }

    /**
     * 获取问答列表
     * @param {*} query 
     * @returns 
     */
    getQuestionList(query) {
        return this.ajax.get(`${this.toolName}/qa/page`, query)
    }

    /**
     * 添加问题
     * @param {*} query 
     * @returns 
     */
    addQuestion(query) {
        return this.ajax.post(`${this.toolName}/qa`, query)
    }

    /**
     * 获取问题详情 管理端
     * @param {*} id 
     * @returns 
     */
    getQuestion(id) {
        return this.ajax.get(`${this.toolName}/qa/${id}`)
    }

    /**
     * 修改问题
     * @param {*} query 
     * @returns 
     */
    editQuestion(query) {
        return this.ajax.put(`${this.toolName}/qa`, query)
    }

    /**
     * 删除问题
     * @param {*} id 
     * @returns 
     */
    removeQuestion(id) {
        return this.ajax.delete(`${this.toolName}/qa/${id}`)
    }

    /**
     * 获取会员管理列表
     * @param {*} query 
     * @returns 
     */
    getMemberList(query) {
        return this.ajax.get(`${this.opName}/member/page`, query)
    }

    /**
     * 添加会员
     * @param {*} query 
     * @returns 
     */
    addMember(query) {
        return this.ajax.post(`${this.opName}/member`, query)
    }

    /**
     * 获取会员详情 管理端
     * @param {*} id 
     * @returns 
     */
    getMemberDetail(id) {
        return this.ajax.get(`${this.opName}/member/${id}`)
    }

    /**
     * 编辑会员
     * @param {*} query 
     * @returns 
     */
    editMember(query) {
        return this.ajax.put(`${this.opName}/member`, query)
    }

    /**
     * 删除会员
     * @param {*} id 
     * @returns 
     */
    removeMember(id) {
        return this.ajax.delete(`${this.opName}/member/${id}`)
    }

    /**
     * 获取会员订单列表
     * @param {*} query 
     * @returns 
     */
    getOrderList(query) {
        return this.ajax.get(`${this.opName}/member/order/page`, query)
    }

    /**
     * 获取会员定制列表
     * @param {*} query 
     * @returns 
     */
    getCustomizaList(query) {
        return this.ajax.get(`${this.opName}/customize/page`, query)
    }

    /**
     * 获取会员定制数据详情 管理端
     * @param {*} id 
     * @returns 
     */
    customizaDetail(id) {
        return this.ajax.get(`${this.opName}/customize/${id}`)
    }

    /**
     * 会员定制审核
     * @param {*} query 
     * @returns 
     */
    customizaExamine(query) {
        return this.ajax.put(`${this.opName}/customize`, query)
    }

    /**
     * 删除会员定制
     * @param {*} id 
     * @returns 
     */
    removeCustomiza(id) {
        return this.ajax.delete(`${this.opName}/customize/${id}`)
    }

    /**
     * 上传单文件
     * @param {*} query 
     * @returns 
     */
    uploadFile(query) {
        return this.ajax.post(`${this.dictModuleName}/file/upload`, query)
    }

    /**
     * 上传图标
     * @param {*} query 
     * @returns 
     */
    uploadIcon(query) {
        return this.ajax.post(`${this.toolName}/icon`, query)
    }


    // 演示的2个页面
    /**
     * 违规文档管理 列表数据
     * @param {*} query 
     * @returns 
     */
    getAlarmList(query) {
        return this.ajax.post(`${this.moduleName}/violation/pageList`, query)
    }

    /**
     * 违规词管理 获取列表
     * @param {*} query 
     * @returns 
     */
    getKeywordList(query) {
        return this.ajax.post(`${this.moduleName}/violation/keyword/pageList`, query)
    }

    /**
     * 添加违规词
     * @param {*} query 
     * @returns 
     */
    addKeywordList(query) {
        return this.ajax.post(`${this.moduleName}/violation/add`, query)
    }

    /**
     * 更改违规词
     * @param {*} query 
     * @returns 
     */
    editKeywordList(query) {
        return this.ajax.post(`${this.moduleName}/violation/changeLevel`, query)
    }

    /**
     * 删除违规词
     * @param {*} id 
     * @returns 
     */
    removeKeywordList(id) {
        return this.ajax.delete(`${this.moduleName}/violation/${id}`)
    }

    /**
     * 租户管理-获取组织列表
     * @param {*} query 
     * @returns 
     */
    getTenantList(query) {
        return this.ajax.post(`${this.orgModuleName}/organization/page/list`, query)
    }

    /**
     * 租户管理-添加组织
     * @param {*} query 
     * @returns 
     */
    addTenant(query) {
        return this.ajax.post(`${this.orgModuleName}/organization/addTenant`, query)
    }

    /** 工具使用次数统计接口 */
    getToolPageTracking(query) {
        return this.ajax.get(`${this.toolName}/tracking/tool/page`, query)
    }

    /** 获取文档库 */
    getDocumentLibrary(query) {
            return this.ajax.post("/document/search/documentCenter/search", query)
        }
        /**
         * 工具端 组织管理页面-获取组织列表
         * @returns 
         */
    toolGetOrgList(token) {
        return this.ajax.get(`${this.orgModuleName}/organization/getUserOrgList`, {}, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 修改密码
     * @returns 
     */
    toolResetPassword(newPassword, token) {
        return this.ajax.put("/usercenter/user/password", {
            oldPassword: newPassword,
            newPassword,

        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具更新用户信息
     */
    toolsUpdate(userInfo, token) {
        return this.ajax.put(`/usercenter/user/update`, {
            username: userInfo.username,
            sex: userInfo.sex,
            birthday: userInfo.birthday,
            email: userInfo.email
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    // 工具更新用户信息
    toolsGetInfo(token) {
        return this.ajax.get(`/usercenter/user/getUserDetails`, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    // 工具更新token
    toolsRefreshToken(refreshToken) {
        return this.ajax.post("/auth/oauth/token", {
            grant_type: "refresh_token",
            refresh_token: refreshToken
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic bGlhbmtvbmc6MTIzNDU2"
            }
        })
    }

    /**
     * 工具端 组织管理页面-创建部门
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolCreateDep(query, token) {
        return this.ajax.post(`${this.orgModuleName}/group`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面-修改部门
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolEditDep(query, token) {
        return this.ajax.put(`${this.orgModuleName}/group/rename`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面-删除部门
     * @param {*} id 
     * @param {*} token 
     * @returns 
     */
    toolRemoveDep(id, token) {
        return this.ajax.delete(`${this.orgModuleName}/group/${id}`, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 获取部门下成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolDepMemberList(query, token) {
        return this.ajax.post(`${this.orgModuleName}/group/member/list`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 获取组织下成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolOrgMemberList(query, token) {
        return this.ajax.post(`${this.orgModuleName}/organization/member/list`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 添加部门成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolAddDepPerson(query, token) {
        return this.ajax.post(`${this.orgModuleName}/group/addTenantUser`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 删除部门成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolRemoveDepPerson(query, token) {
        return this.ajax.delete(`${this.orgModuleName}/group/remove/member`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 删除组织成员
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolRemoveOrgPerson(query, token) {
        return this.ajax.put(`${this.orgModuleName}/organization/tenant/manager/remove/user`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 重置密码
     * @param {*} query 
     * @param {*} token 
     * @returns 
     */
    toolEditPassword(query, token) {
        return this.ajax.put(`${this.orgModuleName}/organization/reset/tenant/password`, query, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     *  工具端 组织管理页面- 下载导入模板
     * @returns 
     */
    toolExportOrgInfo(token) {
        const url = `${this.orgModuleName}/organization/exportOrgTemplate?access_token=${token}`
        window.location.href = '/api/v1' + url
    }

    /**
     * 工具端 组织管理页面- 导入通讯录
     * @param {*} query 
     * @returns 
     */
    toolExportUploadOrg(query, token) {
        return this.ajax.post(`${this.orgModuleName}/organization/importOrgInformation`, query, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + token
            }
        })
    }

    /**
     * 工具端 组织管理页面- 获取租户详情
     * @param {*} query 
     * @returns 
     */
    toolGetUserDetail(query, token) {
        return this.ajax.get(`${this.orgModuleName}/organization/tenant/user/detail/${query.userId}/${query.orgId}`, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }


    /**
     * 获取需求调研列表
     * @param {*} query 
     * @returns 
     */
    getDemandList(query) {
        return this.ajax.post(`${this.opName}/useDemand/pageList`, query)
    }

    /**
     * 删除 需求调研
     * @param {*} id 
     * @returns 
     */
    removeDemand(id) {
        return this.ajax.get(`${this.opName}/useDemand/del/${id}`)
    }

    /**
     * 详情 需求调研
     * @param {*} id 
     * @returns 
     */
    detailDemand(id) {
        return this.ajax.get(`${this.opName}/useDemand/get/${id}`)
    }

    /**
     * 导出 需求调研
     * @param {*} query 
     * @returns 
     */
    exportDemand(query) {
        const url = `${this.opName}/useDemand/export?startDay=${query.startDay}&endDay=${query.endDay}`
        window.location.href = '/api/v1' + url
    }

    // 电子证件模板列表
    getTemplateList(query) {
        return this.ajax.post(`/tool/template/page`, query)
    }

    // 电子证件-删除模板
    removeTemplate(id) {
        return this.ajax.get(`/tool/template/remove/${id}`)
    }

    // 电子证件-删除模板
    addTemplate(query) {
        return this.ajax.post(`/tool/template/add`, query)
    }

    // 电子证件-编辑模板
    editTemplate(query) {
        return this.ajax.post(`/tool/template/edit`, query)
    }

    // 电子证件-详情
    getTemplateDetail(id) {
        return this.ajax.get(`/tool/template/detail/${id}`)
    }

    /**
     * 工具使用策略配置页面 - 查询列表
     * @returns 
     */
    getStrategyToolList({strategyId}) {
        return this.ajax.get(`${this.toolName}/property/${strategyId}`)
    }

    /**
     * 获取策略详情
     * @returns 
     */
    getStrategyDetail({strategyId}) {
        return this.ajax.get(`${this.toolName}/strategy/${strategyId}`)
    }


    /**
     * 前台获取工具策略详情
     * @returns 
     */
    getStrategyByToolId({id}) {
        return this.ajax.get(`${this.toolName}/strategy/front/tool/properties/${id}`)
    }

    /**
     * 添加使用策略
     * @returns 
     */
    addStrategy(query) {
        return this.ajax.post(`${this.toolName}/strategy`,query)
    }

    /**
     * 编辑使用策略
     * @returns 
     */
    updateStrategy(query) {
        return this.ajax.put(`${this.toolName}/strategy`,query)
    }

    /**
     * 编辑使用策略
     * @returns 
     */
    removeStrategy({id}){
        return this.ajax.delete(`${this.toolName}/strategy/${id}`)
    }

    /**
     * 系统使用策略列表
     * @returns 
     */
     getStrategyList(query) {
        return this.ajax.get(`/tool/strategy/page`,query)
    }

    /**
     * 获取可使用策略列表
     * @returns 
     */
     getStrategyAllList(type) {
        return this.ajax.get(`/tool/strategy/ls/${type}`)
    }

    /**
     * 为平添用户授权策略组
     * @returns 
     */
    addAuthToUser(query) {
        return this.ajax.put(`/tool/strategy/authorization`,query)
    }



    /**
     * 工具使用策略配置页面 - 查询工具名称下拉框
     * @param {*} query 
     * @returns 
     */
    getStrategyToolName(query) {
        return this.ajax.get(`${this.toolName}/property/tools`, query)
    }

    /**
     * 工具使用策略配置页面 - 获取可设置的属性（默认）
     * @returns 
     */
    getSetProperty() {
        return this.ajax.get(`${this.toolName}/property/setting`)
    }

    /**
     * 工具使用策略配置页面 - 根据工具id获取可设置的属性
     * @param {*} id 
     * @returns 
     */
    getToolSetProperty(id) {
        return this.ajax.get(`${this.toolName}/property/setting/${id}`)
    }

    /**
     * 工具使用策略配置页面 - 批量添加
     * @param {*} query 
     * @returns 
     */
    batchAddToolProperty(query) {
        return this.ajax.post(`${this.toolName}/property/batch`, query)
    }

    /**
     * 工具使用策略配置页面 - 保存
     * @param {*} query 
     * @returns 
     */
    strategySave(query) {
        return this.ajax.put(`${this.toolName}/property`, query)
    }


    /**
     * 模板类型列表
     * @param {*} query 
     * @returns 
     */
    getTemplateCategoryList(query) {
        return this.ajax.post(`${this.materialName}/template/category/page`, query)
    }
    /**
     * 添加模板类型
     * @param {*} query 
     * @returns 
     */
    addTemplateCategory(query) {
        return this.ajax.post(`${this.materialName}/template/category/add`, query)
    }
    /**
     * 编辑模板类型
     * @param {*} query 
     * @returns 
     */
    editTemplateCategory(query) {
        return this.ajax.post(`${this.materialName}/template/category/edit`, query)
    }

    /**
     *删除模板类型
     * @param {*} id 
     * @returns 
     */
    removeTemplateCategory(id) {
        return this.ajax.get(`${this.materialName}/template/category/remove/${id}`)
    }

     /**
     *获取模板类型详情
     * @param {*} id 
     * @returns 
     */
    getTemplateCategoryDetail(id) {
        return this.ajax.get(`${this.materialName}/template/category/getById/${id}`)
    }



    /**
     * 模板标签列表
     * @param {*} query 
     * @returns 
     */
     getTemplateTagList(query) {
        return this.ajax.post(`${this.materialName}/template/tag/page`, query)
    }


    /**
     * 添加模板标签
     * @param {*} query 
     * @returns 
     */
    addTemplateTag(query) {
        return this.ajax.post(`${this.materialName}/template/tag/add`, query)
    }
    /**
     * 编辑模板标签
     * @param {*} query 
     * @returns 
     */
    editTemplateTag(query) {
        return this.ajax.post(`${this.materialName}/template/tag/edit`, query)
    }
    /**
     * 模板标签详情
     * @param {*} id 
     * @returns 
     */
    getTemplateTagDetail(id) {
        return this.ajax.get(`${this.materialName}/template/tag/getById/${id}`)
    }

    /**
     * 删除模板标签
     * @param {*} id 
     * @returns 
     */
    removeTemplateTag(id) {
        return this.ajax.get(`${this.materialName}/template/tag/remove/${id}`)
    }

    /**
     * 上传附件
     * @param {*} file 
     * @returns 
     */
    sysFileUpload(file) {
        let formData = new FormData()
        formData.append('file',file.raw)
        return this.ajax.post(`${this.materialName}/tmp/file`,formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    /**
     * 获取模板列表-素材库
     * @param {*} query 
     * @returns 
     */
    getTemplateLibraryList(query) {
        return this.ajax.post(`${this.materialName}/template/manager/page`,query)
    }

    /**
     * 新增模板-素材库
     * @param {*} query 
     * @returns 
     */
    addTemplateLibrary(query) {
        return this.ajax.post(`${this.materialName}/template/manager/add`,query)
    }

    /**
     * 编辑模板-素材库
     * @param {*} query 
     * @returns 
     */
    editTemplateLibrary(query) {
        return this.ajax.post(`${this.materialName}/template/manager/edit`,query)
    }

    /**
     * 模板详情-素材库(后台管理)
     * @param {*} id 
     * @returns 
     */
    getMCTemplateLibraryDetail(id) {
        return this.ajax.get(`${this.materialName}/template/manager/getById/${id}/mc`)
    }
    /**
     * 模板详情-素材库( 前台管理)
     * @param {*} id 
     * @returns 
     */
     getTemplateLibraryDetail(id) {
        return this.ajax.get(`${this.materialName}/template/manager/getById/${id}`)
    }

    /**
     * 删除模板-素材库
     * @param {*} id 
     * @returns 
     */
    removeTemplateLibrary(id) {
        return this.ajax.get(`${this.materialName}/template/manager/remove/${id}`)
    }

     /**
     * 推荐模板-素材库
     * @param {*} templateId 
     * @returns 
     */
    getTemplateLibraryRecommend(templateId) {
        return this.ajax.get(`${this.materialName}/template/manager/recommend/${templateId}`)
    }

    /**
     * 根据模板类别获取推荐标签-素材库
     * @param {*} id 
     * @returns 
     */
     getRecommendTag(id) {
        return this.ajax.get(`${this.materialName}/template/category/recommendTag/${id}`)
    }

    /**
     * 下载模板-素材库
     * @param {*} id 
     * @returns 
     */
    downLoadTemplate(id) {
        return `${this.materialName}/template/manager/download/${id}`
    }


    /**
     * 下载量统计
     * @param {*} query 
     * @returns 
     */
    downloadTemplateNum(query) {
        return this.ajax.post(`${this.materialName}/statis/download`,query)
    }

    /**
     * 类型下载统计
     * @param {*} query 
     * @returns 
     */
    downloadTemplateByCate(query) {
        return this.ajax.post(`${this.materialName}/statis/downloadOfCate`,query)
    }

    /**
     *  标签频次统计
     * @param {*} query 
     * @returns 
     */
    templateViewsOfTag(query) {
        return this.ajax.post(`${this.materialName}/statis/viewsOfTag`,query)
    }

    /**
     *  获取系统白名单
     * @param {*} query 
     * @returns 
     */
    getWhiteList() {
        return this.ajax.get(`/whitelist`)
    }

    /**
     *  更新系统白名单
     * @param {*} query 
     * @returns 
     */
    updateWhiteList(query) {
        return this.ajax.post(`/whitelist`,query)
    }
}
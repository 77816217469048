import BaseAPI from '../BaseAPI.js'

/**
 * 文件转换
 */
export default class ToolConvertAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/tc"
        this.dictModuleName = '/dict' // 上传文件

        // this.moduleName = "/admin" // 1205
    }

    /** pdf转word 支持:pdf */
    pdfConverWord(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2word`, query)
    }

    /** pdf转Excel 支持:pdf */
    pdfConverExcel(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2excel`, query)
    }

    /** pdf转Img 支持:pdf */
    pdfConverImage(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2image`, query)
    }

    /** pdf转html 支持:pdf */
    pdfConverHtml(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2html`, query)
    }

    /** pdf转ppt 支持:pdf */
    pdfConverPpt(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2ppt`, query)
    }

    /** ppt转pdf 支持:doc,docx,ppt,pptx,ofd,xls,xlms */
    pptConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2pdf`, query)
    }

    /** pfd提取图片 */
    pdfConverImg(query) {
        return this.ajax.post(`${this.moduleName}/async/content/extract/image`, query)
    }

    /** doc,docx,ppt,pptx,pdf转换ofd */
    pdfConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2ofd`, query)
    }

    /** pdf拆分 */
    pdfSpilt(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/split`, query)
    }

    /** pdf合并 */
    pdfMerge(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/merge`, query)
    }

    /** pdf添加水印 */
    pdfWaterMark(query) {
        return this.ajax.post(`${this.moduleName}/async/security/watermark`, query)
    }

    /** pdf去除水印 */
    pdfRemoveWater(query) {
        return this.ajax.post(`${this.moduleName}/async/security/watermark/remove`, query)
    }

    /** 裁剪图片 */
    cropPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/cropping`, query)
    }

    /** 调整图片大小 */
    adjustPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/resize`, query)
    }

    /** 旋转图片 */
    rotatePicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/rotate`, query)
    }

    /** 图片模糊 */
    vaguePicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/vague`, query)
    }

    /** 图片模糊图片回显 */
    vaguePictureTest(query) {
        return this.ajax.post(`${this.moduleName}/handle/vagueTest`, query)
    }

    /** 压缩图片 */
    crompressPicture(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/condense`, query)
    }

    /** 获取异步处理结果 */
    getOpertateResult(query) {
        return this.ajax.get(`${this.moduleName}/result`, query)
            // return this.ajax.get(`/result`, query)//网安
    }

    /** pdf加密 */
    pdfEncrypt(query) {
        return this.ajax.post(`${this.moduleName}/async/security/encrypt`, query)
    }

    /**pdf解密 */
    pdfDecrypt(query) {
        return this.ajax.post(`${this.moduleName}/async/security/decrypt`, query)
    }

    /** 图片转换ofd */
    imageConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/image2ofd`, query)
    }

    /** 图片转换pdf */
    imageConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/image2pdf`, query)
    }

    /** html转换ofd 上传压缩包 */
    htmlConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/html2ofd`, query)
    }

    /** html转换pdf 上传压缩包 */
    htmlConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/html2pdf`, query)
    }

    /** pdf删除页眉页脚 */
    pdfDelHeader(query) {
        return this.ajax.post(`${this.moduleName}/async/content/header/delete`, query)
    }

    /** pdf文件压缩 */
    pdfCondenseHandle(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/condense`, query)
    }

    /** html转换ofd 传字符串 */
    htmlStrConverOfd(query) {
        return this.ajax.post(`${this.moduleName}/convert/htmlStr2ofd`, query)
    }

    /** html转换pdf 传字符串 */
    htmlStrConverPdf(query) {
        return this.ajax.post(`${this.moduleName}/convert/htmlStr2pdf`, query)
    }
    htmlStrConvertAdd(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/htmlStrConvertAdd`, query)
    }

    /** pdf加页码 */
    pdfPageNumer(query) {
        return this.ajax.post(`${this.moduleName}/async/content/page`, query)
    }

    /** pdf旋转 */
    pdfRotate(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/doc/rotate`, query)
    }

    /** pdf加页眉/页脚 */
    pdfAddHeader(query) {
        return this.ajax.post(`${this.moduleName}/async/content/header`, query)
    }

    /** pdf文本替换 */
    pdfTxtReplace(query) {
        return this.ajax.post(`${this.moduleName}/async/security/replace`, query)
    }

    /** 获取所有的字体 */
    getAllFontFamily() {
        return this.ajax.get(`${this.moduleName}/font/select`)
    }

    /** onlyoffice配置接口 */
    getOnlyOfficeConfig(query, token) {
        if (token) {
            return this.ajax.get(`/document/onlyOffice/tool/config`, query, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        } else {
            return this.ajax.get(`/document/onlyOffice/tool/config`, query)
        }
    }

    /** 图片拼接 */
    getImgStitch(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/imageJoint`, query)
    }

    // 智能证件照、调整图片分辨率、图片调色
    uploadFileBase64Temp(query) {
        return this.ajax.post(`${this.moduleName}/base64/upload`, query)
    }

    // 图片分割
    handleImageSplit(query) {
        return this.ajax.post(`${this.moduleName}/handle/imageSplit`, query)
    }

    /** gif制作 */
    gifMake(query) {
        return this.ajax.post(`${this.moduleName}/async/handle/generateGIF`, query)
    }

    /** 图片加水印  */
    imgageWatermark(query) {
        return this.ajax.post(`${this.moduleName}/security/imageMark`, query)
    }


    // 电子证件生成 -  打开模板
    openTemplate(file) {
        return this.ajax.post(`${this.moduleName}/open`, file)
    }

    // 电子证件生成 -  保存模板
    saveTemplate(query) {
        return this.ajax.post(`${this.moduleName}/save`, query)
    }


    // 电子证件生成 -  套打模板
    sceneTemplate(query) {
        return this.ajax.post(`${this.moduleName}/scene/template`, query)
    }

    // pdf转长图
    pdfConverLongPic(query) {
        return this.ajax.post(`${this.moduleName}/async/convert/2longImage`, query)
    }

    // 音频转文字
    audioConverText(query) {
        return this.ajax.post(`${this.moduleName}/other/voice2text`, query)
    }

    // 电子文件封装
    otherPackage(query) {
        return this.ajax.post(`${this.moduleName}/other/package`, query)
    }

    // 文件加二维码
    fileToAddQrCode(query){
        return this.ajax.post(`${this.moduleName}/async/security/addQrCode`, query)
    }

}
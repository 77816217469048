import Vue from 'vue'
import { Button, MessageBox,Message ,Icon ,Dialog} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'

// import Pdf from './src/Pdf.vue'


Vue.component(Button.name, Button);
Vue.component(Icon.name, Icon);
Vue.component(Message.name, Message);
Vue.component(MessageBox.name, MessageBox);
Vue.prototype.$message = Message
Vue.use(Dialog)

import videoJs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videoJs;


// if(window.location.search.indexOf('&pdf=pdf') != -1){
//   new Vue({
//     render: h => h(Pdf)
//   }).$mount('#app')
// }else{
  new Vue({
    render: h => h(App)
  }).$mount('#app')
// }

import BaseAPI from '../BaseAPI.js'

/**
 * 工具服务API
 * @author Daniel
 * @date 
 */
export default class ToolsAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/tool"

        // this.moduleName = "/admin" // 1205
    }

    /**获取工具分类列表 */
    getCategoryList(query) {
        return this.ajax.get(`${this.moduleName}/category/ls`, query)
    }

    /**获取所有工具列表 */
    getAllToolsList(query) {
        return this.ajax.get(`${this.moduleName}/tool`,query)
    }

    /**获取最受欢迎工具 */
    getMostToolList(query) {
        return this.ajax.get(`${this.moduleName}/popular/page/lk`, query)
    }

    /**获取工具列表 */
    getToolList(query) {
        return this.ajax.get(`${this.moduleName}/tool/page/lk`, query)
    }

    /** 获取格式分类的工具*/
    getGroupTool(query) {
        return this.ajax.get(`${this.moduleName}/tool/group/lk`, query)
    }

    /**获取工具详情*/
    getToolDetail(id,query) {
        return this.ajax.get(`${this.moduleName}/tool/${id}/lk`,query)
    }

    /** 添加收藏 */
    addCollect(query) {
        return this.ajax.post(`${this.moduleName}/collection`, query)
    }

    /** 删除收藏 */
    removeCollect(id) {
        return this.ajax.delete(`${this.moduleName}/collection/${id}`)
    }

    /** 打开工具统计接口 */
    openToolTracking(query) {
        return this.ajax.post(`${this.moduleName}/tracking/tool/open`, query)
    }

    /** 获取工具工具接口 */
    getToolPageTracking(query) {
        return this.ajax.get(`${this.moduleName}/tracking/tool/page`, query)
    }

    /** 使用工具统计接口 */
    useToolTracking(query) {
        return this.ajax.post(`${this.moduleName}/tracking/tool/use`, query)
    }

    /** 意见反馈接口 */
    addFeedBack(query) {
        return this.ajax.post(`/operation/feedback/add`, query)
    }

    /** 工具详情页面的推荐 */
    toolRecommend(id) {
        return this.ajax.get(`${this.moduleName}/tool/recommend/${id}`)
    }


    // 工具相关推荐--获取除当前工具以外的所有工具
    getToolExceptById(id) {
        return this.ajax.get(`${this.moduleName}/tool/getToolExceptById/${id}`)
    }

    // 工具相关推荐--获取当前工具的推荐
    getRecommendToolById(query) {
        return this.ajax.get(`${this.moduleName}/recommend/getRecommendToolById`, query)
    }

    // 工具相关推荐--配置当前工具
    addRecommendBatch(query) {
        return this.ajax.post(`${this.moduleName}/recommend/addRecommendBatch`, query)
    }

    /**
     * 获取工具使用配置策略的值
     * @param {*} id 
     * @returns  文件上传数量、文件大小、使用次数、录屏时长
     */
    getToolPropertyValues(id) {
        return this.ajax.get(`${this.moduleName}/strategy/front/tool/properties/${id}`)
    }

    /** 获取工具使用次数 */
    getToolUseCount() {
        return this.ajax.get(`${this.moduleName}/property/num`)
    }

    /** 前端处理工具使用工具次数加加 */
    addUseToolCount() {
        return this.ajax.post(`${this.moduleName}/property/num`)
    }


    /** 是否可以使用工具 */
    isUseTool(){
        return this.ajax.get(`${this.moduleName}/tool/pass`)
    }

}
<template>
  <div class="ofd-viewer">
  </div>
  
</template>

<script>
  import { require } from '../utils/require.js'
  import { getQueryParams } from "../utils/query.js"
  import { Store } from '@lk/cache'
  export default {
    data(){
      return {
        ofdReader: {
          loaded: false,
          instance: null
        }
      }
    },
    mounted () {
      this.install()
    },
    methods: {
      //安装阅读器
      install(){
        if(!window.SCOFDReader){
          require("ofd-mobile-reader/SCOFDReaderMobile.umd.min.js").then(()=> {
            console.log("移动版阅读器加载完成")
            let width = "100%"
            let height = "100%"
            // 文件渲染比例
            // let ratio = window.devicePixelRatio || 1
            // 文件服务地址
            let server = window.origin+"/prod-api/fs/";
            // 是否显示标题
            let showHeader = false
            // 是否显示页码
            let showPageNumber = true
            let showOpenButton = false;
            // 单文件模式（开启则再打开文件时，会关闭自动以前打开的文件）
            let singleMode = true
            // 是否允许滚动回弹效果
            let overScrollX = false
            let overScrollY = true
            let showNavigation = getQueryParams("model")=='teamwork';
            let showNavToggleButton = getQueryParams("model")!='teamwork';
            this.ofdReader.instance = new window.SCOFDReaderMobile(this.$el, Store.get('lic'), {
                maxZoom:1.5,
                width: width,
                showOpenButton: showOpenButton,
                height: height,
                showNavigation: showNavigation,
                showNavToggleButton: showNavToggleButton,
                showHeader: showHeader,
                singleMode: singleMode,
                showPageNumber: showPageNumber,
                overScrollX: overScrollX,
                overScrollY: overScrollY,
                server:server,
                showOpenLoading:true
            });

            this.ofdReader.instance.on("document:ready", () => {
              if(getQueryParams("model") != 'teamwork' && getQueryParams("pageIndex")){
                this.ofdReader.instance.goto(Number(getQueryParams("pageIndex")))
              }
             
            })

            this.$emit("onInited");
          })
        }
      },
      /**
       * 根据Ticket进行登陆
       */ 
      openByTicket(ticket){
        try{
          this.ofdReader.instance.openFile(ticket)
        }catch(e){
          console.log(e)
        }
      },
      save(){
         try{
          this.ofdReader.instance.save().then(() => {
            this.$message.success('保存成功')
          })
        }catch(e){
          console.log(e)
        }
      }



    }
  }
</script>

<style scoped>
.ofd-viewer{
  width: 100%;
  height: 100%;
 
}

</style>
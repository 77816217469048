<template>
  <div class="header-box">
    <div  class="bottom"  v-show="showBox" :class="{'show':isShow}">
      <div class="container" @click.stop >
        <div class="col-6"  ><img @click="showSetting(0)"  class="img" v-show="isShowList" src="../img/list.png" /></div>
        <div class="col-6"  @click="showSetting(1)" ><img class="img" src="../img/sf.png" /></div>
        <div class="col-6"  @click="showSetting(2)" ><img class="img" src="../img/page.png" /></div>
        <div class="col-6"  @click="showSetting(3)" ><img class="img" src="../img/fy.png" /></div>
        <div class="col-6"  @click="showSetting(4)" ><img class="img"  src="../img/sz.png" /></div>
      </div>

      <div class="pageBox" >
        <span>{{page.nowPage}}</span>
        <span>/</span>
        <span>{{page.pageNum}}</span>
      </div>

      <!-- 左侧列表 -->
      <div class="slide" :class="{'width80':isSlide}"  @click.stop>
        <div class="slide-title">资源列表</div>
        <img class="back" v-show='viewer.depth!=0' @click="backFolder" src="../img/back.png" />
        <div class="title" v-for="item in viewer.showFiles" :key='item.id' 
          @click="handleCommand('changeTicket',item)"
        ><img :src="thumbIcon(item)" alt="">{{item.name}}</div>
      </div>

      <!-- 缩放 -->
      <div class="zoom" v-show='isZoom'>
        <div @click="handleCommand('scale',0)">放大</div>
        <div class='scale' >{{showScaleNumber}}%</div>
        <div @click="handleCommand('scale',1)">缩小</div>
      </div>

      <!-- 翻页模式设置 -->
      <div class="zoom tool"  v-show='isTool'>
        <div @click="handleCommand('setpageView',1)">翻页</div>
        <div @click="handleCommand('setpageView',2)">连续</div>
      </div>

      <!-- 翻页 -->
      <div class="zoom progress"  v-show='isProgress'>
        <div @click="handleCommand('setpage','previous')">上一页</div>
        <div @click="handleCommand('setpage','next')">下一页</div>
      </div>

      <!-- 工具 -->
      <div class="zoom settings"  v-show='isSettings'>
        <!-- <div @click="handleCommand('download')">下载</div> -->
        <div @click="handleCommand('print')">打印</div>
      </div>
    </div>
    


    <div class="mask" v-show="isSlide" @click.stop="closeMask"></div>

  </div>
</template>

<script>
  import iconFolder from '../img/folder.png'
  import iconDefault from '../img/icon-def.png'
  import iconUnknown from '../img/icon-unknown.png'
  import iconImage from '../img/icon-picture.png'
  import iconOFD from '../img/icon-ofd.png'
  import iconAudio from '../img/icon-audio.png'
  import iconWord from '../img/icon-word.png'
  import { documentAPI } from '@lk/api'
 import { getQueryParams } from "../utils/query.js"
  //后缀映射
  const suffixIconCache = {
    def: iconDefault,
    folder: iconFolder,
    unknown: iconUnknown,
    png: iconImage,
    jpg: iconImage,
    jpeg: iconImage,
    bmp: iconImage,
    ofd: iconOFD,
    doc: iconWord,
    docx: iconWord,
    mp3: iconAudio,
    mp4: iconAudio
  }
  
  export default {
    data(){
      return{
        isShow:false,
        isSlide:false,
        isZoom:false,
        isTool:false,
        isProgress:false,
        isSettings:false,
        isShowList:true,
        id:'',
        drawer:false,
        num:1,
        list:[],
        viewer:{
          showFiles:[],
          depth:0,
          current:{},
          paths:[],
          files:[]
        },
        showBox:true,
        showScaleNumber:0
      }

    },
    props:{
      page:{},
      type:{},
      scale:{},
      showPrint:{
        type:Boolean
      }
    },
    created(){
      // 如果不是分享，不显示列表
      if(getQueryParams("id")){
        this.isShowList = true;
      }else{
        this.isShowList = false;
      }
      if(getQueryParams("type") == 'signature'){
        this.showBox = false
      }
    },
    methods:{
      stop(){},
      // 图标
      thumbIcon(doc){ 
        let iconKey = "unknown";
        switch(doc.suffix){
          case 'ofd':
            iconKey = 'ofd'
            break;
          case 'png':
            iconKey = 'png'
            break;
          case 'jpg':
            iconKey = 'png'
            break;
          case 'jpeg':
            iconKey = 'png'
            break;
          case 'doc':
            iconKey = 'doc'
            break;
          case 'docx':
            iconKey = 'docx'
            break;
          case null:
            iconKey = 'folder'
            break;
          case 'mp3':
            iconKey = 'mp3'
            break;
          case 'mp4':
            iconKey = 'mp4'
            break;
          default:
            iconKey = 'unknown'
            break
        }
        return suffixIconCache[iconKey] 
      },

      handleCommand(type,value){
        switch(type){
          // 缩放
          case 'scale': 
            this.$emit('menuClick',"scale",value)
            break;
          
          // 翻页
          case 'setpage':
            this.$emit('menuClick',"setpage",value)
            // this.$emit('gotoPage',value)
            break;

          // 列表点击切换文档
          case 'changeTicket':

           
            if(value.type==0){
              // 进入文件夹
              this.enterFolder(value);
              if(this.viewer.files.length==0){
                this.viewer.files = this.viewer.showFiles;
              }
              
              
            }else{
              this.$emit('menuClick',"getTicketChange",value);
              this.isSlide = false;
            }
            break;
          
          // 翻页模式设置
          case 'setpageView':
            this.$emit('menuClick',"setpageView",value)
            this.num=1;
            break;

          // 打印
          case 'print':
            this.$emit('menuClick','print')
            break;
          // 下载
          case 'download':
            this.$emit('menuClick','download')
            break;
        }
      },

      //侧边列表开关
      slideList(){
        this.isSlide = !this.isSlide;
      },
      closeMask(){
        this.isSlide = false;
      },

      // 进入文件夹
      enterFolder(folder){
        this.addPaths(folder)
      },
      // 进入目录
      addPaths(folder){
        let query={
          id:folder.documentId || folder.id,
          name:folder.name,
          pageNum:1,
          pageSize:100
        }
        documentAPI.queryChildren(query).then(res=>{
          this.viewer.current.children = res.data; 
          this.viewer.showFiles = res.data;
        })
        if (!folder.children) {
          folder.children = []
        }

        this.viewer.current = folder
        // this.viewer.showFiles = []
        this.viewer.depth++
        this.viewer.paths.push(folder);
      },
      // 后退文件夹
      backFolder(){
        const index = this.viewer.paths.length - 2
        if (index > -2) {
          this.gopaths(index)
        }
      },
      //退出目录
      gopaths(index){
         if (index >= 0) {
          const folder = this.viewer.paths[index]
          this.viewer.paths = this.viewer.paths.splice(0, index + 1)
          this.viewer.current = folder
          this.viewer.showFiles = folder.children
          this.viewer.depth--
        } else {
          this.viewer.paths = this.viewer.paths.splice(0, 0)
          this.viewer.current = null
          this.viewer.showFiles = this.viewer.files
          this.viewer.depth = 0
        }
      },


      // 显示隐藏其他
      showSetting(tag){
        
        switch(tag){
          case 0:
            this.isSlide = !this.isSlide;

            this.isZoom=false;
            this.isTool=false;
            this.isProgress=false;
            this.isSettings = false;
            break;
          case 1:
            this.isZoom = !this.isZoom;
            this.isSlide=false;
            this.isTool=false;
            this.isProgress=false;
            this.isSettings = false;
            break;
          case 2:
            this.isTool = !this.isTool;
            this.isSlide=false;
            this.isZoom=false;
            this.isProgress=false;
            this.isSettings = false;
            break;
          case 3:
            this.isProgress = !this.isProgress;
            this.isSlide=false;
            this.isZoom=false;
            this.isTool=false;
            this.isSettings = false;
            break;
          case 4:
            this.isProgress = false;
            this.isSlide=false;
            this.isZoom=false;
            this.isTool=false;
            this.isSettings = !this.isSettings;
            break
        }
      },

    },
  }
</script>

<style scoped>
.scale{
  display: none;
}

.bottom{
  position: fixed;
  left: 0;
  bottom: -90px;
  right: 0;
  height: 40px;
  line-height: 40px;
  padding: 5px;
  background-color: #fff;
  color: #FFFFFF;
  z-index: 999;
 transition: all .4s linear;

}
.back{
  position: absolute;
  top: 65px;
  right: 30px;
}
.pageBox{
  display: none;
  position: fixed;
  right: 0;
  bottom: 140px;
  font-size: 16px;
  background: #fff;
  color: #333;
  line-height: normal;
  padding: 10px 10px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  box-shadow: 0 0 20px #666;
  height: 20px;
}
.mask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0,0,0.5);
  z-index: 1;
}
.container{
  display:flex ;
  justify-content: space-around;
}
.container img{
  width: 25px;
  height: 25px;
}
.bottom.show{
  bottom: 0;
  transition: all .4s linear;
  box-shadow: 0 0 20px #999;
}
.col-6{
  width:20%;
  text-align: center;
  color:#333;
  font-size:16px;
  padding-top: 6px;
  box-sizing: border-box;
  position: relative;
}
.slide{
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 12;
    width: 300px;
    height: 100%;
    background: white;
    color: #333;
    font-size: 16px;
    box-sizing: border-box;
    padding: 40px;
    overflow: scroll;
    transition: all .4s linear;
}
.slide div.title{
  border-bottom: 1px solid #ddd;
}
.slide div.title img{
  width: 40px;
  margin-right: 15px;
  vertical-align: middle;
}
.slide .slide-title{
  font-size: 20px;
  font-weight: bold;
  color:#073dd6;
}
.width80{
  left: 0;
  transition: all .4s linear;
  box-shadow: 0 0 50px #999;
}
.zoom{
  position: absolute;
  bottom:50px;
  left: 20%;
  width: 20%;
  background: #fff;
  font-size: 16px;
  color: #333;
  text-align: center;
  box-shadow: 0 -20px 20px -7px #999;
}
.tool{
  left: 40%;
}
.progress{
  left: 60%;
}
.settings{
  left: 80%;
}
@media (min-width: 769px) {
   .header-box{
     position: relative!important;
    display: flex;
    justify-content: center;
    background: #323639!important;
    font-size: 14px!important;
    line-height: 20px!important;
    box-shadow: 0 0 10px #000;
    top: 0;
    height: 50px;
    left: 0;
    z-index: 99;
  }
  .bottom{
    position: relative!important;
    display: flex;
    justify-content: center;
    background: #323639!important;
    font-size: 14px!important;
    line-height: 20px!important;
    top: 0;
    left: 0;
    
  }
  .bottom .container{
    display: none;
  }
  .slide{
    display: none;
  }
  .zoom{
    display: flex!important;
    position: static!important;
    width: auto!important;
    box-shadow: none!important;
    background: transparent!important;
    color: #fff!important;
    margin-left: 10px;
     font-size: 14px!important;
    border-left:1px solid  rgba(255, 255, 255, 0.3);
    height: 20px;
    margin-top: 10px;
    padding-left: 10px;
  }
  .zoom div{
    margin-right: 5px;
    cursor: pointer;
  }
  .pageBox{
    display: block;
    position: static!important;
    box-shadow: none!important;
    background: transparent!important;
    color: #fff!important;
    font-weight: normal!important;
    font-size: 14px!important;
    padding: 0!important;
    margin-top: 10px;
  }
  .scale{
    display: block;
    background: #00000080;
    padding: 1px 10px ;
  }
  .pageBox span:nth-child(1){
    background: #00000080;
    padding: 1px 10px ;
    margin-right: 5px;
  }
  .pageBox span{
    margin-right: 5px;
  }
 
  
}
</style>
import BaseAPI from '../BaseAPI.js'

/**
 * 广告服务API
 * 含：轮播图
 * @author Daniel
 * @date 
 */
export default class AdvertAPI extends BaseAPI {
    constructor() {
        super()

        this.moduleName = "/operation"
    }

    /**获取首页轮播图列表 */
    slideShowList(query) {
        return this.ajax.get(`${this.moduleName}/advert/ls`, query)
    }

    /** 会员服务页面- 企业定制请求接口 */
    customizaApply(query) {
        return this.ajax.post(`${this.moduleName}/customize`, query)
    }

    /** 获取普通用户空间大小 */
    normalSize(query) {
        return this.ajax.get(`${this.moduleName}/member/space/normal`, query)
    }

    /** 获取vip用户空间大小 */
    vipMemberSize(query) {
        return this.ajax.get(`${this.moduleName}/member/space/vip`, query)
    }

    /** 获取会员列表 */
    getMemberList(query) {
        return this.ajax.get(`${this.moduleName}/member/ls`, query)
    }

    /** 购买会员 */
    memberOrderPlay(query) {
        return this.ajax.post(`${this.moduleName}/member/order`, query)
    }

    /** 提交需求调研 */
    addDemand(query) {
        return this.ajax.post(`${this.moduleName}/useDemand/add`, query)
    }

}